<template>
  <b-form ref="form" :validated="validated" novalidate>
    <b-alert :show="!!ndfIsValidated" variant="success">{{
      'Note de frais validée'
    }}</b-alert>
    <b-alert :show="!!ndfIsRejected" variant="danger">{{
      `Note de frais rejetée : ${ndf.RejectionReason}`
    }}</b-alert>
    <b-form-row>
      <b-col class="text-center">
        <img
          v-if="ndfIsImage"
          :src="ndf.Photo"
          class="img-ndf"
          @click="resetPhoto()"
          title="Cliquez ici pour remplacer le document"
        />
        <fa-icon
          v-else
          icon="file-pdf"
          class="img-ndf"
          @click="resetPhoto()"
          title="Cliquez ici pour remplacer le document"
        />
        <br />
        <a href="#" @click="openContent(ndf)">
          Ouvrir dans une nouvelle fenêtre
        </a>
      </b-col>
    </b-form-row>
    <b-form-group label="Date">
      <date-picker
        v-model="ndfDate"
        :config="configs.picker.date"
        required
        :disabled="ndfIsReadOnly"
      />
    </b-form-group>
    <b-form-group :description="categoryDescription">
      <template #label>Catégorie</template>
      <b-form-select
        v-model="ndf.CategoryId"
        :options="categories"
        value-field="Id"
        text-field="Name"
        required
        :disabled="ndfIsReadOnly"
      />
    </b-form-group>
    <b-form-group v-if="showProjectList" label="Projet">
      <LiveSearchSelect
        v-model="project"
        :items="filteredProjectsOptions"
        :required="isProjectRequired"
      >
        <template #list-item="{ text, altText }">
          {{ text }}
          <template v-if="altText">
            <br /><small>{{ altText }}</small>
          </template>
        </template>
      </LiveSearchSelect>
    </b-form-group>
    <GuestList
      v-if="showGuestList"
      v-model="guests"
      :readonly="ndfIsReadOnly"
      :validated="validated"
    />
    <b-form-row v-if="showPhoneUsageSwitch">
      <b-col cols="5">
        <b-form-group label="Usage">
          <b-form-radio-group
            v-model="phoneUsage"
            :options="usageOptions"
            button-variant="outline-primary"
            size="sm"
            buttons
          />
        </b-form-group>
      </b-col>
      <b-col cols="7">
        <b-form-checkbox
          v-if="showUsageProConfirmation"
          v-model="confirmUsageProOnly"
          :state="usageProConfirmationState"
          size="sm"
          >Je certifie sur l'honneur que ces frais sont exclusivement liés à un
          usage professionnel.</b-form-checkbox
        >
        <b-alert v-else variant="info" class="p-1" show
          ><small
            >Forfait téléphonique à usage mixte : seul 50% du montant de la
            facture est remboursé.</small
          ></b-alert
        >
      </b-col>
    </b-form-row>
    <b-form-group label="Description">
      <b-form-textarea
        v-model.trim="description"
        :state="descriptionValid"
        :required="isDescriptionRequired"
        :disabled="ndfIsReadOnly"
      />
    </b-form-group>
    <VatItemList
      ref="vatList"
      :ndf="ndf"
      :category="category"
      :validated="validated"
    />
    <b-form-row>
      <b-col>
        <h5>Total</h5>
      </b-col>
      <b-col class="text-right">
        <h5>HT :</h5>
        <h5>TVA :</h5>
        <h5>TTC :</h5>
      </b-col>
      <b-col
        v-if="showPhoneUsageSwitch && !showUsageProConfirmation"
        cols="2"
        class="text-right"
      >
        <h5>
          <del class="text-info">{{ exclAmountRaw }} €</del>
        </h5>
        <h5>
          <del class="text-info">{{ vatAmountRaw }} €</del>
        </h5>
        <h5>
          <del class="text-info">{{ inclAmountRaw }} €</del>
        </h5>
      </b-col>
      <b-col
        :cols="showPhoneUsageSwitch && !showUsageProConfirmation ? 2 : 4"
        class="text-right"
      >
        <h5 :class="{ 'text-danger': amountState === false }">
          {{ exclAmount }} €
        </h5>
        <h5>{{ vatAmount }} €</h5>
        <h5>{{ inclAmount }} €</h5>
      </b-col>
    </b-form-row>
    <b-form-invalid-feedback :state="amountState">{{
      amountFeedback
    }}</b-form-invalid-feedback>
  </b-form>
</template>

<script>
import moment from 'moment'
import {
  DateMixin,
  DateTimePickerConfigMixin,
  ProjectMixin,
  VatMixin
} from '@/mixins'
import { mapState } from 'pinia'
import { useStore } from '@/stores'
import { CLIENT_NAME, PROJECTS, PROJECT } from '@/stores/getter-types.js'
import { NdfStatus, VatRates, PhoneUsage } from '@/assets/js/Constants.js'
import { isEmpty } from '@/stores/utils'
import GuestList from '@/components/Ndf/GuestList.vue'
import VatItemList from '@/components/Ndf/VatItemList.vue'
import LiveSearchSelect from '@/components/Tool/LiveSearchSelect.vue'
import { useNdf } from '@/composables/useNdf'
import { useTooltips } from '@/composables/useTooltips'

const RX_GUEST = /CONVIVE: ([^,]+), SOCIETE: ([^,]+), POSTE: (.+)/

export default {
  name: 'NdfDetails',
  components: { GuestList, VatItemList, LiveSearchSelect },
  mixins: [DateMixin, DateTimePickerConfigMixin, ProjectMixin, VatMixin],
  props: {
    ndf: { type: Object, required: true },
    categories: { type: Array, required: true }
  },
  data() {
    return {
      validated: false,
      project: undefined,
      guests: [],
      phoneUsage: PhoneUsage.Mixed,
      usageOptions: [
        { text: 'Mixte', value: PhoneUsage.Mixed },
        { text: 'Pro uniquement', value: PhoneUsage.ProOnly }
      ],
      confirmUsageProOnly: false,
      description: ''
    }
  },
  setup() {
    const { splitDescription, isNdfInConflict } = useNdf()
    const { tooltipConflict } = useTooltips()

    return { splitDescription, isNdfInConflict, tooltipConflict }
  },
  computed: {
    ...mapState(useStore, [CLIENT_NAME, PROJECTS, PROJECT]),
    ndfDate: {
      get() {
        var d = moment(this.ndf.Date)
        return d
      },
      set(value) {
        if (value) {
          this.ndf.Date = this.parseDate(value)
        }
      }
    },
    ndfIsImage() {
      return (
        (this.ndf.Photo && this.ndf.Photo.indexOf('image/') > -1) ||
        (!!this.ndf.DocumentContentType &&
          this.ndf.DocumentContentType.indexOf('image/') > -1)
      )
    },
    ndfIsRejected() {
      return this.ndf.Status == NdfStatus.Rejected
    },
    ndfIsValidated() {
      return this.ndf.Status == NdfStatus.Validated
    },
    ndfIsReadOnly() {
      return (
        this.ndf.Status == NdfStatus.Validated ||
        this.ndf.Status == NdfStatus.Payed
      )
    },
    category() {
      return this.categories.find(cat => cat.Id == this.ndf.CategoryId)
    },
    categoryDescription() {
      return this.category?.Description ?? ''
    },
    showProjectList() {
      return this.category?.Code == '625600' // Restaurant
    },
    isProjectRequired() {
      return !this.description?.trim()
    },
    isDescriptionRequired() {
      return !this.project
    },
    showGuestList() {
      return this.category?.Code == '625700' // Reception / invitation
    },
    showPhoneUsageSwitch() {
      return this.category?.Code == '626200' // Frais de télécommunication
    },
    showUsageProConfirmation() {
      return this.showPhoneUsageSwitch && this.phoneUsage == PhoneUsage.ProOnly
    },
    usageProConfirmationState() {
      return !this.validated || !this.showUsageProConfirmation
        ? undefined
        : this.confirmUsageProOnly
    },
    filteredProjectsOptions() {
      const date = this.ndfDate.toDate()

      return this[PROJECTS].filter(
        p =>
          !isEmpty(p.ClientId) &&
          ((!p.StartDate && !p.EndDate) ||
            (new Date(p.EndDate) >= date && new Date(p.StartDate) <= date))
      ).map(p => ({
        key: p.Id,
        value: p,
        text: `${(name => (name ? `${name} - ` : ''))(
          this[CLIENT_NAME](p.ClientId)
        )}${this.formatProjectName(p)}`,
        altText: this.formatProjectPeriod(p),
        ...p
      }))
    },
    descriptionValid() {
      return !this.validated
        ? null
        : !!(this.description && this.description.match(/^[^\s].+[^\s]$/))
    },
    validVatItems() {
      return this.ndf.VatItems.filter(i => i.Amount !== undefined)
    },
    inclAmount() {
      return this.formatAmount(
        this.getInclAmount(this.validVatItems) * this.refundRate
      )
    },
    vatAmount() {
      return this.formatAmount(
        this.getVatAmount(this.validVatItems) * this.refundRate
      )
    },
    exclAmount() {
      return this.formatAmount(
        this.getExclAmount(this.validVatItems) * this.refundRate
      )
    },
    inclAmountRaw() {
      return this.formatAmount(this.getInclAmount(this.validVatItems))
    },
    vatAmountRaw() {
      return this.formatAmount(this.getVatAmount(this.validVatItems))
    },
    exclAmountRaw() {
      return this.formatAmount(this.getExclAmount(this.validVatItems))
    },
    refundRate() {
      return this.showPhoneUsageSwitch && !this.showUsageProConfirmation
        ? 0.5
        : 1
    },
    isAmountTooLow() {
      const exclAmount = this.getExclAmount(this.ndf.VatItems)

      return (
        this.category?.MinAmount !== undefined &&
        this.category?.MinAmount !== null &&
        exclAmount < this.category.MinAmount
      )
    },
    isAmountTooHigh() {
      const exclAmount = this.getExclAmount(this.ndf.VatItems)

      return (
        this.category?.MaxAmount !== undefined &&
        this.category?.MaxAmount !== null &&
        exclAmount > this.category.MaxAmount
      )
    },
    isAmountValid() {
      return !this.isAmountTooLow && !this.isAmountTooHigh
    },
    amountState() {
      return this.validated && !this.isAmountValid ? false : undefined
    },
    amountFeedback() {
      return this.isAmountTooLow
        ? `Le montant HT doit être supérieur ou égal à ${this.category?.MinAmount} €`
        : this.isAmountTooHigh
        ? `Le montant HT doit être inférieur ou égal à ${this.category?.MaxAmount} €`
        : ''
    }
  },
  watch: {
    'ndf.CategoryId': function () {
      this.updateDescription()
      this.updateProjectId()
      this.updatePhoneUsage()
      this.updateGuests()
    },
    project: function () {
      this.updateDescription()
      this.updateProjectId()
    },
    guests: function () {
      this.updateGuests()
    },
    phoneUsage: function (value) {
      if (value == PhoneUsage.Mixed) {
        this.confirmUsageProOnly = false
      }

      this.updatePhoneUsage()
    },
    description: function () {
      this.updateDescription()
    }
  },
  mounted() {
    this.description = this.showProjectList
      ? this.splitDescription(this.ndf.Description).description
      : this.ndf.Description

    this.project = this[PROJECTS].find(p => p.Id == this.ndf.ProjectId)

    if (this.showGuestList) {
      this.guests = [...this.ndf.Guests]
    }

    if (this.showPhoneUsageSwitch) {
      this.phoneUsage = this.ndf.PhoneUsage
      this.confirmUsageProOnly = this.ndf.PhoneUsage == PhoneUsage.ProOnly
    }
  },
  methods: {
    resetPhoto() {
      if (!this.ndfIsReadOnly) {
        this.ndf.Photo = ''
      }
    },
    updateDescription() {
      const description = [this.description?.trim()]

      if (this.showProjectList && this.project) {
        description.unshift(
          `CLIENT: ${this[CLIENT_NAME](this.project?.ClientId)}`
        )
      }

      this.ndf.Description = description.filter(r => !!r).join('\n')
    },
    updateProjectId() {
      if (this.showProjectList) {
        this.ndf.ProjectId = this.project?.Id
      } else {
        delete this.ndf.ProjectId
      }
    },
    updatePhoneUsage() {
      if (this.showPhoneUsageSwitch) {
        this.ndf.PhoneUsage = this.phoneUsage
      } else {
        delete this.ndf.PhoneUsage
      }
    },
    updateGuests() {
      if (this.showGuestList) {
        this.ndf.Guests = this.guests
      } else {
        delete this.ndf.Guests
      }
    },
    validatePhoneUsage() {
      return (
        !this.showPhoneUsageSwitch ||
        !this.showUsageProConfirmation ||
        this.confirmUsageProOnly
      )
    },
    validate() {
      this.validated = true

      return (
        this.validatePhoneUsage() &&
        this.$refs.vatList.validate() &&
        this.isAmountValid &&
        this.$refs.form.checkValidity()
      )
    },
    getVatRateItem(r) {
      return { value: r, text: this.formatVatRate(r) }
    },
    formatVatRate(rate) {
      return `${rate * 100} %`.replace('.', ',')
    },
    openContent(ndf) {
      var pdfWindow = window.open('')
      pdfWindow.document.write(
        "<iframe width='100%' height='100%' src='" + ndf.Photo + "'></iframe>"
      )
      pdfWindow.document.close()
    }
  }
}
</script>

<style lang="scss" scoped>
img,
svg {
  &.img-ndf {
    width: 160px;
    height: 120px;
  }
}

img.img-ndf {
  object-fit: contain;
}
</style>
