import Vue from 'vue'
import RemoteIcon from '@/components/Timesheet/RemoteIcon.vue'

export const useRemoteIcon = () => {
  const RemoteIconComponent = Vue.extend(RemoteIcon)

  const createInstance = (date, active, warning, onChange) => {
    const instance = new RemoteIconComponent({
      propsData: {
        date,
        active,
        warning
      }
    })
    instance.$on('changed', onChange)
    instance.$mount()

    return instance
  }

  return { createInstance }
}
