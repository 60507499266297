<template>
  <div>
    <div id="fixed-progress" class="mb-3">
      <b-container>
        <b-row>
          <b-col>
            <b-progress :max="max" :animated="loading">
              <b-progress-bar
                :value="score"
                :label="`Profil completé à ${completeRate}%.`"
              ></b-progress-bar>
            </b-progress>
            <b-alert
              variant="success"
              :show="alerts.success.countdown"
              @dismissed="alerts.success.countdown = 0"
              dismissible
              fade
              >Profil enregistré avec succès.</b-alert
            >
            <b-alert
              variant="danger"
              :show="alerts.failure.countdown"
              dismissible
              fade
              @dismissed="alerts.failure.countdown = false"
              ><span v-html="alerts.failure.reason"></span>
            </b-alert>
          </b-col>
        </b-row>
      </b-container>
    </div>
    <b-container id="enrolment-form">
      <b-row>
        <b-col md="8" id="identity">
          <IdentityBox
            :loading="loading"
            @user-saved="showSuccess"
            @user-not-saved="showFailure"
          />
        </b-col>
        <b-col md="4" id="photo">
          <ImageProfileBox
            :loading="loading"
            @user-saved="showSuccess"
            @user-not-saved="showFailure"
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col md="6" id="contactInfo">
          <ContactInfoBox
            :loading="loading"
            @user-saved="showSuccess"
            @user-not-saved="showFailure"
          />
        </b-col>
        <b-col md="6" id="skills">
          <SkillBox
            :loading="loading"
            @user-saved="showSuccess"
            @user-not-saved="showFailure"
            @add-skill-failed="showSkillAddFailure"
            @remove-skill-failed="showSkillDeleteFailure"
          />
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>
import { mapState, mapActions } from 'pinia'
import { useStore } from '@/stores'
import { MAX_SCORE, SCORE, COMPLETION_RATE } from '@/stores/getter-types'
import { LOAD_USER_PROFILE } from '@/stores/action-types'
import IdentityBox from '@/components/User/IdentityBox.vue'
import ContactInfoBox from '@/components/User/ContactInfoBox.vue'
import ImageProfileBox from '@/components/User/ImageProfileBox.vue'
import SkillBox from '@/components/User/SkillBox.vue'

export default {
  name: 'Profile',
  components: { IdentityBox, ContactInfoBox, ImageProfileBox, SkillBox },
  data() {
    return {
      alerts: {
        success: {
          countdown: 0
        },
        failure: {
          countdown: false,
          reason: String
        }
      },
      loading: false
    }
  },
  computed: {
    ...mapState(useStore, {
      profileScore: SCORE,
      max: MAX_SCORE,
      profileCompleteness: COMPLETION_RATE
    }),
    score() {
      return this.loading ? this.max : this.profileScore
    },
    completeRate() {
      return this.loading ? '' : this.profileCompleteness
    }
  },
  mounted() {
    this.loading = true

    this[LOAD_USER_PROFILE](this.$adal.user.profile)
      .catch(error => {
        this.showFailure(
          'Echec lors de la récuperation de votre profil : ' + error.message
        )
      })
      .finally(() => (this.loading = false))
  },
  methods: {
    ...mapActions(useStore, [LOAD_USER_PROFILE]),
    showSuccess() {
      this.alerts.success.countdown = 2
    },
    showFailure(evtData) {
      this.alerts.failure.reason = evtData
      this.alerts.failure.countdown = true
    },
    showSkillAddFailure({ skill, error }) {
      this.showFailure(
        `Echec lors de l'ajout de la compétence <em>${skill}</em> :<br /><strong>${error.message}</strong>`
      )
    },
    showSkillDeleteFailure({ skill, error }) {
      this.showFailure(
        `Echec lors de la suppression de la compétence <em>${skill}</em> :<br /><strong>${error.message}</strong>`
      )
    }
  }
}
</script>
<style lang="scss" scoped>
#home {
  padding-top: 100px;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  color: #033f3c;
}

.container {
  text-align: center;
}

#enrolment-form {
  padding-top: 45px;

  .row {
    padding-top: 15px;
    padding-bottom: 15px;

    &:first-child {
      padding-top: 0;
    }
  }
}
#fixed-progress {
  position: fixed;
  width: 100%;
  z-index: 100;
  min-height: 30px;
  background-color: white;
}
.progress {
  height: 30px;
}
.alert {
  margin-top: 5px;
  margin-bottom: 5px;
}

@media (max-width: 768px) {
  #enrolment-form .row {
    padding: 0px;
  }
  .col-form-label {
    color: rebeccapurple;
    text-align: left;
  }
}
</style>
<style>
@media (max-width: 768px) {
  #enrolment-form .row {
    padding: 0px;
  }
  .col-form-label {
    text-align: left;
  }
}
</style>
