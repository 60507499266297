<template>
  <b-card header="Info. Administratives" body-class="text-center">
    <Loader v-show="saving" absolute />
    <b-form
      :validated="formValidated"
      class="card-text"
      @submit="ValidateForm"
      novalidate
    >
      <AdministrativeInfoCardItem
        id="accountGroup"
        label="Compte :"
        label-for="account"
        v-model="account"
        :dirty="accountDirty"
        required
      />
      <AdministrativeInfoCardItem
        id="tiersGroup"
        label="Tiers :"
        label-for="tiers"
        v-model="tiers"
        :dirty="tiersDirty"
        required
      />
      <AdministrativeInfoCardItem
        id="analytiqueGroup"
        label="Analytique :"
        label-for="analytique"
        v-model="analytique"
        :dirty="analytiqueDirty"
        class="mb-0"
        required
      />
      <b-button v-if="dirty" type="submit" variant="primary"
        ><fa-icon :icon="['far', 'save']" /> Enregistrer</b-button
      >
    </b-form>
  </b-card>
</template>
<script>
import Loader from '@/components/Loader.vue'
import AdministrativeInfoCardItem from './AdministrativeInfoCardItem'
import { mapActions } from 'pinia'
import { useStore } from '@/stores'
import { SAVE_USER_ADMINISTRATIVE_INFO } from '@/stores/action-types.js'

export default {
  name: 'AdministrativeInfoCard',
  components: { Loader, AdministrativeInfoCardItem },
  props: {
    userProfile: { type: Object, required: true }
  },
  data() {
    return {
      account: this.userProfile.Compte,
      tiers: this.userProfile.Tiers,
      analytique: this.userProfile.Analytique,
      saving: false,
      formValidated: false
    }
  },
  computed: {
    accountDirty() {
      return this.account != this.userProfile.Compte
    },
    tiersDirty() {
      return this.tiers != this.userProfile.Tiers
    },
    analytiqueDirty() {
      return this.analytique != this.userProfile.Analytique
    },
    dirty() {
      return this.accountDirty || this.tiersDirty || this.analytiqueDirty
    }
  },
  methods: {
    ...mapActions(useStore, [SAVE_USER_ADMINISTRATIVE_INFO]),
    ValidateForm(e) {
      e.preventDefault()
      e.stopPropagation()

      const form = e.target
      this.formValidated = true

      if (form.checkValidity() === true) {
        this.saving = true

        this[SAVE_USER_ADMINISTRATIVE_INFO]({
          upn: this.userProfile.Upn,
          account: this.account,
          tiers: this.tiers,
          analytique: this.analytique
        })
          .then(() => {
            this.userProfile.Compte = this.account
            this.userProfile.Tiers = this.tiers
            this.userProfile.Analytique = this.analytique

            this.$emit('user-saved')
          })
          .catch(error => this.$emit('user-not-saved', error))
          .finally(() => {
            this.saving = false
            this.formValidated = false
          })
      }
    }
  }
}
</script>
