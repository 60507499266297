import { defineStore } from 'pinia'
import * as State from './state-keys'
import * as Getters from './getter-types'
import * as Mutations from './mutation-types'
import * as Actions from './action-types'
import state from './state'
import getters from './getters'
import actions from './actions'

const useStore = defineStore('store', {
  state,
  getters,
  actions
})

export { useStore, State, Getters, Mutations, Actions }
