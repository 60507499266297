<template>
  <small class="remote-icon" :class="{ pointer: toggleEnabled }" @click="toggleActive">
    <fa-icon
      icon="laptop-house"
      title="Télétravail"
      :class="innerActive ? 'text-primary' : 'text-secondary'"
    />
    <fa-icon
      v-if="warning"
      icon="exclamation-triangle"
      title="Conflit"
      class="text-warning"
    />
  </small>
</template>

<script>
import { useFeature, remoteActivityOnCalendar } from '@/composables/useFeature';

export default {
  name: 'RemoteIcon',
  model: {
    prop: 'active',
    event: 'changed'
  },
  props: {
    date: { type: Date },
    active: { type: Boolean, default: false },
    warning: { type: Boolean, default: false }
  },
  data() {
    return {
      innerActive: this.active
    }
  },
  setup(props) {
    const { isRemoteFeatureActive } = useFeature(remoteActivityOnCalendar)
    const toggleEnabled = isRemoteFeatureActive(props.date)

    return { toggleEnabled }
  },
  methods: {
    toggleActive() {
      if (this.toggleEnabled) {
        this.innerActive = !this.innerActive
        this.$emit('changed', this.innerActive)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.remote-icon {
  .text-secondary {
    opacity: 0.5;
  }

  &.pointer {
    .text-primary:hover {
      color: var(--danger) !important;
    }

    svg:first-child {
      cursor: pointer;
    }
  }
}
</style>
