import { computed, ref } from "vue";
import { defineStore } from "pinia";
import { NdfService } from '@/services'

export const useNdfStore = defineStore('ndfs', () => {
  // State
  const categories= ref([])

  // Getters
  const getCategory = computed(() => id => categories.value.find(c => c.Id == id))

  // Mutations
  const setCategories = items => categories.value = items

  // Actions
  const loadNdfCategories = () =>
    NdfService.GetCategories()
      .then(response => setCategories(response.data))
      .catch(err => {
        console.error(
          '[STORE] Uncaught error while retrieving NDF categories: ',
          err
        )

        throw err
      })

  return {
    categories,
    getCategory,
    setCategories,
    loadNdfCategories
  }
})