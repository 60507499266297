import { computed } from  'vue'
import { useRemoteActivity } from '@/composables/useRemoteActivity'
import { useStore } from '@/stores'
import { LOAD_NDF_CATEGORIES } from '../stores/action-types'
import { NDF_CATEGORY } from '../stores/getter-types'
import { NDF_CATEGORIES } from '../stores/state-keys'

const splitDescription = value => {
  const [_, __, client, description] = `${value}\n`.match(/(CLIENT: (.+)\n)?(.*)\n?/)

  return { description, client }
}

const { isRemoteDay } = useRemoteActivity()
const isRestaurant = cat => cat?.Code === '625600'

export const useNdf = () => {
  const store = useStore()
  const getCategory = ndf => store[NDF_CATEGORY](ndf.CategoryId)
  const isNdfInConflict = ndf => isRestaurant(getCategory(ndf)) && isRemoteDay(ndf)
  
  return {
    categories: computed(() => store[NDF_CATEGORIES]),
    loadNdfCategories: store[LOAD_NDF_CATEGORIES],
    splitDescription,
    isNdfInConflict
  }
}
