import * as State from './state-keys'
import * as Mutations from './mutation-types'
import { fillDocument } from './utils'

const mutations = {
  [Mutations.SET_USER_PROFILE](profile) {
    this[State.USER_PROFILE] = profile
  },
  [Mutations.SET_USER_FIRST_NAME](firstName) {
    this[State.USER_PROFILE].FirstName = firstName
  },
  [Mutations.SET_USER_LAST_NAME](lastName) {
    this[State.USER_PROFILE].LastName = lastName
  },
  [Mutations.SET_USER_HEALTH_NUMBER](healthNumber) {
    this[State.USER_PROFILE].HealthNumber = healthNumber
  },
  [Mutations.SET_USER_JOB_TITLE](jobTitle) {
    this[State.USER_PROFILE].JobTitle = jobTitle
  },
  [Mutations.SET_USER_EMAIL_ADDRESS](emailAddress) {
    this[State.USER_PROFILE].EmailAddress = emailAddress
  },
  [Mutations.SET_USER_PHONE_NUMBER](phoneNumber) {
    this[State.USER_PROFILE].PhoneNumber = phoneNumber
  },
  [Mutations.SET_USER_STREET_NAME](streetName) {
    this[State.USER_PROFILE].Address.StreetName = streetName
  },
  [Mutations.SET_USER_ZIP_CODE](zipCode) {
    this[State.USER_PROFILE].Address.ZipCode = zipCode
  },
  [Mutations.SET_USER_CITY](city) {
    this[State.USER_PROFILE].Address.City = city
  },
  [Mutations.SET_USER_COUNTRY](country) {
    this[State.USER_PROFILE].Address.Country = country
  },
  [Mutations.SET_USER_IS_AVAILABLE](isAvailable) {
    this[State.USER_PROFILE].IsAvailable = isAvailable
  },
  [Mutations.SET_USER_SHORT_BIO](shortBio) {
    this[State.USER_PROFILE].ShortBio = shortBio
  },
  [Mutations.SET_USER_SKILLS](skills) {
    this[State.USER_PROFILE].Skills = skills
    this[State.USER_PROFILE].Skills.sort()
  },
  [Mutations.SET_USER_ADR](adr) {
    this[State.USER_PROFILE].Adr = adr
  },
  [Mutations.SET_USER_PREFERRED_LOCATION](prefLocation) {
    this[State.USER_PROFILE].PreferredLocation = prefLocation
  },
  [Mutations.PUSH_USER_SKILL](skill) {
    this[State.USER_PROFILE].Skills.push(skill)
    this[State.USER_PROFILE].Skills.sort()
  },
  [Mutations.POP_USER_SKILL](skill) {
    const idx = this[State.USER_PROFILE].Skills.findIndex(s => s == skill)

    if (idx > -1) {
      this[State.USER_PROFILE].Skills.splice(idx, 1)
    }
  },
  [Mutations.SET_USER_PHOTO](photo) {
    fillDocument(this[State.USER_PROFILE].Photo, photo)
  },
  [Mutations.SET_USER_IDENTITY_CARD](doc) {
    fillDocument(this[State.USER_PROFILE].Documents.Identity, doc)
  },
  [Mutations.SET_USER_HEALTH_INSURANCE_EVIDENCE](doc) {
    fillDocument(
      this[State.USER_PROFILE].Documents.HealthInsuranceEvidence,
      doc
    )
  },
  [Mutations.SET_USER_CURRICULUM_VITAE](doc) {
    fillDocument(this[State.USER_PROFILE].Documents.CurriculumVitae, doc)
  },
  [Mutations.SET_USER_BANK_STATEMENT](doc) {
    fillDocument(this[State.USER_PROFILE].Documents.BankStatement, doc)
  },
  [Mutations.SET_ALL_SKILLS](skills) {
    this[State.ALL_SKILLS] = skills
    this[State.ALL_SKILLS].sort()
  },
  [Mutations.SET_CLIENTS](clients) {
    this[State.CLIENTS] = clients
  },
  [Mutations.REMOVE_CLIENT](id) {
    const idx = this[State.CLIENTS].findIndex(c => c.Id == id)

    if (idx > -1) {
      this[State.CLIENTS].splice(idx, 1)
    }
  },
  [Mutations.SET_PROJECTS](projects) {
    this[State.PROJECTS] = projects
  },
  [Mutations.SET_PROJECT](project) {
    const idx = this[State.PROJECTS].findIndex(p => p.Id == project.Id)

    if (idx == -1) {
      this[State.PROJECTS].push(project)
    } else {
      this[State.PROJECTS].splice(idx, 1, project)
    }
  },
  [Mutations.REMOVE_PROJECT](id) {
    const idx = this[State.PROJECTS].findIndex(p => p.Id == id)

    if (idx > -1) {
      this[State.PROJECTS].splice(idx, 1)
    }
  },
  [Mutations.SET_USERS](users) {
    this[State.USERS] = users
  },
  [Mutations.REMOVE_USER](upn) {
    const idx = this[State.USERS].findIndex(u => u.Upn == upn)

    if (idx > -1) {
      this[State.USERS].splice(idx, 1)
    }
  },
  [Mutations.SET_NOTIFICATIONS](notifications) {
    this[State.NOTIFICATIONS] = notifications
  },
  [Mutations.SET_ES_PHOTO]({ upn, photo }) {
    const usr = this[State.USERS].find(u => u.Upn == upn)

    if (!!usr) {
      fillDocument(usr.Photo, photo)
    }
  },
  [Mutations.SET_NDF_CATEGORIES](categories) {
    this[State.NDF_CATEGORIES] = categories
  }
}

export default mutations
