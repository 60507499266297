import axios from 'axios'
import getToken from './adal.js'
import { ActivityRoutes } from './routes/index.js'

async function AddRemoteActivity(date) {
  const token = await getToken()
  return axios.post(ActivityRoutes.MY_REMOTE_ACTIVITY, date, {
    headers: { Authorization: token }
  })
}

async function GetMyRemoteActivity() {
  const token = await getToken()
  return axios.get(ActivityRoutes.MY_REMOTE_ACTIVITY, {
    headers: { Authorization: token }
  })
}

async function GetAllRemoteActivity() {
  const token = await getToken()
  return axios.get(ActivityRoutes.ALL_REMOTE_ACTIVITY, {
    headers: { Authorization: token }
  })
}

async function DeleteRemoteActivity(date) {
  const token = await getToken()
  return axios.delete(ActivityRoutes.MY_REMOTE_ACTIVITY_ON(date), {
    headers: { Authorization: token }
  })
}

export { AddRemoteActivity, GetMyRemoteActivity, GetAllRemoteActivity, DeleteRemoteActivity }
