<template>
  <b-container>
    <b-row>
      <b-col>
        <div id="calendar">
          <Calendar
            ref="calendar"
            @events-loading="loading = true"
            @events-loaded="
              loading = false
              slots = $event
            "
          />
          <b-alert variant="success" class="text-center mt-3 mb-0" show>
            <dl class="row mb-0">
              <dd class="col-3 text-right mb-0">Imputation :</dd>
              <dt class="col-3 text-left mb-0">
                {{ imputedDayCount }} j /
                {{ ($refs.calendar || {}).workingDayCount }}
                jours ouvrés
              </dt>
              <dd class="col-3 text-right mb-0">CA <u>estimé</u> :</dd>
              <dt class="col-3 text-left mb-0">
                {{ estimatedGrossRevenue }} &euro;
                <span
                  v-b-tooltip.hover
                  title="Estimation basée sur le tarif renseigné dans les propriétés
                    des projets concernés."
                  class="float-right"
                >
                  <fa-icon icon="info-circle" />
                </span>
              </dt>
            </dl>
          </b-alert>
          <b-table
            :items="aggregatedSlots"
            :fields="config.table.aggregatedFields"
            sort-by="id"
            :busy="loading"
            @row-clicked="onRowClicked"
            hover
          >
            <template #table-busy><Loader /></template>
            <template #table-colgroup>
              <col style="width: auto" />
              <col style="width: 100px" />
              <col style="width: 100px" />
            </template>
            <template #cell(realDuration)="{ item, value }">
              {{ value
              }}<sup
                v-if="item.duration != item.realDuration"
                v-b-tooltip.html
                :title="`<strong>${value} ${
                  value <= 1 ? 'jour' : 'jours'
                }</strong> ce mois-ci sur <strong>${
                  item.duration
                }</strong> en tout.`"
                style="opacity: 0.5"
                >?</sup
              >
            </template>
            <template #cell(realRevenue)="{ item, value }">
              {{ value }}&nbsp;&euro;<sup
                v-if="value != item.revenue"
                v-b-tooltip.html
                :title="`<strong>${value} €</strong> ce mois-ci sur <strong>${item.revenue} €</strong> en tout.`"
                style="opacity: 0.5"
                >?</sup
              >
            </template>
            <template #row-details="row">
              <b-table
                :items="row.item.slots"
                :fields="config.table.fields"
                :busy="loading"
                small
              >
                <template #table-busy><Loader /></template>
                <template #table-colgroup>
                  <col style="width: 100px" />
                  <col style="width: 100px" />
                  <col style="width: auto" />
                  <col style="width: 100px" />
                  <col style="width: 100px" />
                </template>
                <template #cell(realDuration)="{ item, value }">
                  {{ value
                  }}<sup
                    v-if="item.duration != item.realDuration"
                    v-b-tooltip.html
                    :title="`<strong>${value} ${
                      value <= 1 ? 'jour' : 'jours'
                    }</strong> ce mois-ci sur <strong>${
                      item.duration
                    }</strong> en tout.`"
                    style="opacity: 0.5"
                    >?</sup
                  >
                </template>
                <template #cell(realRevenue)="{ item, value }">
                  {{ value }}&nbsp;&euro;<sup
                    v-if="value != item.revenue"
                    v-b-tooltip.html
                    :title="`<strong>${value} €</strong> ce mois-ci sur <strong>${item.revenue} €</strong> en tout.`"
                    style="opacity: 0.5"
                    >?</sup
                  >
                </template>
              </b-table>
            </template>
          </b-table>
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { onMounted } from 'vue'
import { ProjectMixin } from '@/mixins'
import { mapState, mapActions } from 'pinia'
import { useStore } from '@/stores'
import { PROJECTS } from '@/stores/state-keys'
import { LOAD_CLIENTS, LOAD_PROJECTS } from '@/stores/action-types'
import { isEmpty } from '@/stores/utils'
import moment from 'moment'
moment.locale('fr')
import Calendar from '@/components/Timesheet/Calendar.vue'
import Loader from '@/components/Loader.vue'
import { useNdf } from '../composables/useNdf'

export default {
  name: 'Timesheet',
  components: { Calendar, Loader },
  mixins: [ProjectMixin],
  data() {
    return {
      slots: [],
      config: {
        table: {
          aggregatedFields: [
            {
              key: 'id',
              label: 'Projets',
              formatter: v => {
                const p = this.projectsFormatted.find(p => p.value == v)
                return p ? p.text : 'Inconnu'
              },
              sortByFormatted: (v, key, item) => {
                return !isEmpty(item.clientId)
                  ? '0-' + item.name
                  : !item.name.startsWith('-')
                  ? '1-' + item.name
                  : '2-' + item.name
              }
            },
            {
              key: 'realDuration',
              label: '# jours',
              formatter: this.formatDays,
              class: 'text-right'
            },
            {
              key: 'realRevenue',
              label: 'CA',
              formatter: this.formatRevenue,
              class: 'text-right'
            }
          ],
          fields: [
            {
              key: 'start',
              label: 'Début',
              formatter: this.formatDate
            },
            {
              key: 'end',
              label: 'Fin',
              formatter: this.formatDate
            },
            {
              key: 'description',
              label: 'Description',
              formatter: v =>
                v != null && v.length > 100 ? v.substring(0, 100) + '...' : v
            },
            {
              key: 'realDuration',
              label: '',
              formatter: this.formatDays,
              class: 'text-right'
            },
            {
              key: 'realRevenue',
              label: '',
              formatter: this.formatRevenue,
              class: 'text-right'
            }
          ]
        }
      },
      loading: false
    }
  },
  setup() {
    const { loadNdfCategories } = useNdf()

    onMounted(() => loadNdfCategories())
  },
  computed: {
    ...mapState(useStore, [PROJECTS]),
    estimatedGrossRevenue() {
      return this.slots.reduce((p, c) => {
        const project = this[PROJECTS].find(pj => pj.Id == c.projectId)

        if (project) {
          p += project.Price * c.realDuration
        }

        return p
      }, 0)
    },
    aggregatedSlots() {
      return this.slots.reduce((a, c) => {
        const prj = this[PROJECTS].find(p => p.Id == c.projectId)
        let project = a.find(e => e.id == c.projectId)

        if (!project) {
          project = {
            id: c.projectId,
            clientId: prj?.ClientId,
            name: prj?.Name,
            slots: [],
            duration: 0,
            realDuration: 0,
            revenue: 0,
            realRevenue: 0,
            _showDetails: false
          }

          a.push(project)
        }

        const revenue = prj ? prj.Price * c.duration : 0
        const realRevenue = prj ? prj.Price * c.realDuration : 0

        project.slots.push({ ...c, revenue, realRevenue })
        project.duration += c.duration
        project.revenue += revenue
        project.realDuration += c.realDuration
        project.realRevenue += realRevenue

        return a
      }, [])
    },
    imputedDayCount() {
      return this.aggregatedSlots.reduce((p, c) => p + c.realDuration, 0)
    }
  },
  async mounted() {
    this[LOAD_CLIENTS]().catch(err => {
      console.error('There was an error: ', err)
    })

    this[LOAD_PROJECTS]().catch(err => {
      console.error('There was an error: ', err)
    })
  },
  methods: {
    ...mapActions(useStore, [LOAD_CLIENTS, LOAD_PROJECTS]),
    onRowClicked(data) {
      data._showDetails = !data._showDetails
    },
    formatDate(date, colName, slot) {
      const formatStr = slot.allDay ? 'DD/MM/YYYY' : 'DD/MM/YYYY HH:mm'

      return date ? moment.utc(date).format(formatStr) : '--'
    },
    formatDays(duration, colName, slot) {
      return Math.round((duration + Number.EPSILON) * 1000) / 1000
    },
    formatRevenue(revenue, colName, slot) {
      return slot.realRevenue != revenue ? slot.realRevenue : revenue
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@fullcalendar/core/main.css';
@import '~@fullcalendar/daygrid/main.css';
@import '~@fullcalendar/timegrid/main.css';

#calendar {
  margin-top: 20px;

  ::v-deep .fc-bgevent {
    font-size: 14px;

    &.public-holiday {
      background-color: rgba(24, 176, 185, 0.3);
      opacity: 1;
    }

    &.fc-nonbusiness {
      background-color: rgba(24, 176, 185);
    }

    .fc-event-title {
      margin: 0.5em;
      margin-left: 10px;
      font-size: 0.85em;
      font-style: italic;
      color: #000;
    }
  }
}
</style>
