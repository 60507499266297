import { NdfStatus, VatZone } from '@/assets/js/Constants.js'

export class Project {
  ClientId
  Closed
  DayCount
  Deleted
  DeletedAt
  Description
  DueDays
  EndDate
  Id
  Name
  Price
  PurchaseOrder
  AxonautId
  StartDate
  Upn
  ValidatedBy
  ValidatedDate
  Vat

  constructor(project) {
    // Shallow copy /!\
    Object.assign(this, project)
  }
}

export class Ndf {
  Photo
  Date // Mostly used as JSON string internally
  CategoryId
  VatItems
  Status
  IntraCommunityPurchase
  VatZone

  constructor() {
    this.Photo = ''
    this.Date = new Date() // Legacy
    this.CategoryId = null
    this.VatItems = []
    this.Status = NdfStatus.Pending
    this.IntraCommunityPurchase = false
    this.VatZone = VatZone.France
  }

  // Deep clone needed for VatItems
  static clone(ndf) {
    // Legacy way
    // Note: Works because Date property is used as string internally
    //       and other properties than VatItems are safe.
    return JSON.parse(JSON.stringify(ndf))

    // Cleaner way
    // Note: Implement VatItem class
    //       Double check Date behavior
    // const clone = new Ndf()
    // clone.Photo = ndf.Photo
    // clone.Date = new Date(ndf.Date)
    // clone.CategoryId = ndf.CategoryId
    // clone.VatItems =  ndf.VatItems.map(vi => VatItem.clone(vi))
    // clone.Status = ndf.Status
    // clone.IntraCommunityPurchase = ndf.IntraCommunityPurchase
    // clone.VatZone = ndf.VatZone
  }
}
