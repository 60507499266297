import Vue from 'vue'
import App from './App.vue'
import router from './router'
import { createPinia, PiniaVuePlugin } from 'pinia'
import Vue2Filters from 'vue2-filters'
import BootstrapVue from 'bootstrap-vue'
import '@/assets/css/custom.scss'
import datePicker from 'vue-bootstrap-datetimepicker'
import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css'

Vue.use(datePicker)

import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faIdCard,
  faFileMedical,
  faFile,
  faMoneyCheckAlt,
  faDownload,
  faCheckCircle,
  faTimesCircle,
  faPlus,
  faMinus,
  faTimes,
  faEdit,
  faTrashAlt,
  faBan,
  faCamera,
  faFilePdf,
  faFileImage,
  faFileExport,
  faSpinner,
  faEuroSign,
  faBrain,
  faTasks,
  faInfoCircle,
  faChevronUp,
  faChevronDown,
  faLaptopHouse,
  faReceipt,
  faCalendar,
  faUserTie,
  faLaptopCode,
  faUser,
  faUserCheck,
  faPowerOff,
  faThLarge,
  faStar as faStarFilled,
  faExclamationTriangle
} from '@fortawesome/free-solid-svg-icons'
import {
  faGrinStars,
  faMeh,
  faFrown,
  faEye,
  faEyeSlash,
  faCircle,
  faSave,
  faStar as faStarEmpty
} from '@fortawesome/free-regular-svg-icons'
import {
  FontAwesomeIcon,
  FontAwesomeLayers,
  FontAwesomeLayersText
} from '@fortawesome/vue-fontawesome'

library.add(
  faIdCard,
  faFileMedical,
  faFile,
  faMoneyCheckAlt,
  faDownload,
  faCheckCircle,
  faTimesCircle,
  faPlus,
  faMinus,
  faTimes,
  faEdit,
  faTrashAlt,
  faBan,
  faCamera,
  faFilePdf,
  faFileImage,
  faGrinStars,
  faMeh,
  faFrown,
  faEye,
  faEyeSlash,
  faFileExport,
  faCircle,
  faSpinner,
  faEuroSign,
  faSave,
  faBrain,
  faTasks,
  faInfoCircle,
  faChevronUp,
  faChevronDown,
  faLaptopHouse,
  faReceipt,
  faCalendar,
  faUserTie,
  faLaptopCode,
  faUser,
  faUserCheck,
  faPowerOff,
  faThLarge,
  faStarEmpty,
  faStarFilled,
  faExclamationTriangle
)

Vue.component('fa-icon', FontAwesomeIcon)
Vue.component('fa-layers', FontAwesomeLayers)
Vue.component('fa-layers-text', FontAwesomeLayersText)

Vue.use(BootstrapVue)
Vue.use(Vue2Filters)
Vue.config.productionTip = false

import { default as Adal } from 'vue-adal'
Vue.use(Adal, {
  config: {
    tenant: process.env.VUE_APP_ADAL_TENANT,
    // Application ID
    clientId: process.env.VUE_APP_ADAL_CLIENTID,
    // Host URI
    redirectUri: process.env.VUE_APP_ADAL_HOST_URI,
    cacheLocation: 'localStorage'
  },
  requireAuthOnInitialize: true,
  router: router
})

// import { default as AdalMock } from '@/assets/js/AdalMock.js'
// Vue.use(AdalMock, {
//   user: {
//     profile: {
//       name: 'Jérôme Lebrun',
//       given_name: 'Jérôme',
//       family_name: 'Lebrun',
//       roles: ['ES_SALARIED']
//     }
//   },
//   token: ''
// })

Vue.use(PiniaVuePlugin)

new Vue({
  router,
  pinia: createPinia(),
  render: h => h(App)
}).$mount('#app')
