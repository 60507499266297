<template>
  <b-container>
    <b-toast
      v-model="failureToastVisible"
      title="Erreur"
      variant="danger"
      toaster="b-toaster-top-full"
      solid
    >
      {{ alerts.failure.reason }}
    </b-toast>
    <b-row no-gutters>
      <b-col class="col-2 col-md-4 col-lg-6">
        <b-button v-show="!adminMode" variant="primary" @click="onEditClick()">
          <fa-icon icon="plus" />
          <span class="d-none d-md-inline"> Ajouter une note de frais</span>
        </b-button>
        <b-button v-show="adminMode" variant="success" @click="onExportClick()">
          <fa-icon icon="file-export" />
          <span class="d-none d-md-inline"> Exporter</span>
        </b-button>
        <b-modal
          ref="ndfModal"
          title="Note de frais"
          @ok="onNdfValidated"
          lazy
          scrollable
          no-close-on-backdrop
          :no-close-on-esc="processing"
          :hide-header-close="processing"
        >
          <Camera
            ref="camera"
            v-if="!pictureTaken"
            v-model="currentNdf.Photo"
            @ready-state-changed="onReadyStateChanged"
          />
          <NdfDetails
            v-else
            ref="details"
            :ndf="currentNdf"
            :categories="categories"
          />
          <template v-if="!isNdfReadOnly" #modal-footer="{ ok, cancel }">
            <b-alert
              :show="isNdfInConflict(currentNdf)"
              variant="warning"
              class="w-100"
            >
              <fa-icon
                icon="exclamation-triangle"
                class="ml-auto text-warning btn-icon"
              />
              {{ tooltipConflict.title }}
            </b-alert>
            <b-button
              v-show="!isCreateMode"
              variant="danger"
              class="mr-auto"
              :disabled="processing"
              @click="deleteNdf()"
              ><fa-icon icon="trash-alt" /> Supprimer</b-button
            >
            <b-button
              variant="secondary"
              :disabled="processing"
              @click="cancel()"
              ><fa-icon icon="ban" /> Annuler</b-button
            >
            <b-button
              v-if="!pictureTaken"
              variant="primary"
              :disabled="!cameraReady"
              @click="$refs.camera.capture()"
              ><fa-icon icon="camera" /> Suivant</b-button
            >
            <b-button
              v-else
              variant="primary"
              :disabled="processing"
              @click="ok()"
              ><fa-icon icon="edit" /> Valider</b-button
            >
          </template>
          <template v-else #modal-footer="{ cancel }">
            <b-button variant="primary" @click="cancel()">Fermer</b-button>
          </template>
        </b-modal>
        <b-modal
          ref="exportModal"
          title="Export des notes de frais"
          @ok="onConfirmExport"
          lazy
          no-close-on-backdrop
        >
          <p>
            <b-form-checkbox
              :disabled="new Date().getMonth() < 11"
              v-model="isClosing"
              >Export de clôture {{ new Date().getFullYear() }}</b-form-checkbox
            >
          </p>
          <p>
            Vous vous apprêtez à exporter toutes les notes de frais déclarées
            jusqu'au <b>{{ endBoundary }}</b> actuellement validées.
          </p>
          <p>
            Les fichiers d'export vont être générés et stockés sur le serveur
            <i>s'ils n'ont pas déjà été exportés précédemment</i>.
          </p>
          <ul>
            <li>
              Document comptable (CSV)
              <fa-icon
                v-show="!!this.export.csv.variant"
                :icon="this.export.csv.icon"
                :class="'text-' + this.export.csv.variant"
                :spin="this.export.csv.icon == 'spinner'"
              />
              <span :class="'text-' + this.export.csv.variant">
                {{ this.export.csv.message }}
              </span>
              <b-link
                v-show="this.export.csv.variant == 'danger'"
                @click="onForceExport"
              >
                forcer
              </b-link>
            </li>
            <li>
              Justificatifs (PDF)
              <fa-icon
                v-show="!!this.export.pdf.variant"
                :icon="this.export.pdf.icon"
                :class="'text-' + this.export.pdf.variant"
                :spin="this.export.pdf.icon == 'spinner'"
              />
              <span :class="'text-' + this.export.pdf.variant">
                {{ this.export.pdf.message }}
              </span>
            </li>
            <li v-show="!!this.export.csv.variant || !!this.export.pdf.variant">
              Opération {{ this.export.status }}
              <fa-icon
                v-show="!!this.export.variant"
                :icon="this.export.icon"
                :class="'text-' + this.export.variant"
                :spin="this.export.icon == 'spinner'"
              />
              <span :class="'text-' + this.export.variant">
                {{ this.export.message }}
              </span>
            </li>
          </ul>
          <template #modal-footer="{ ok, cancel }">
            <b-button variant="secondary" @click="cancel()"
              ><fa-icon icon="ban" /> Annuler</b-button
            >
            <b-button variant="primary" @click="ok()"
              ><fa-icon icon="file-export" /> Exporter</b-button
            >
          </template>
        </b-modal>
      </b-col>
      <b-col class="text-right col-10 col-md-8 col-lg-6">
        <b-input-group>
          <template #prepend>
            <b-select
              v-model="sliceM"
              :options="slicesM"
              @change="loadNdfs()"
            />
            <b-select
              v-model="sliceY"
              :options="slicesY"
              @change="loadNdfs()"
            />
          </template>
          <b-input
            type="search"
            v-model="search"
            placeholder="Date, catégorie, description, statut..."
          />
        </b-input-group>
        <b-input-group :prepend="`${totalRows} / ${ndfs.length}`">
          <template #prepend>
            <span></span>
          </template>
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            :disabled="totalRows <= perPage"
            aria-controls="ndf-table"
          ></b-pagination>
        </b-input-group>
      </b-col>
    </b-row>
    <b-row no-gutters>
      <b-col>
        <b-table
          id="ndf-table"
          :items="this.ndfs"
          :fields="fields"
          :filter="search"
          :filter-included-fields="filterIncluded"
          :busy="loading"
          class="mt-3"
          sort-icon-left
          :per-page="perPage"
          :current-page="currentPage"
          @filtered="onFiltered"
        >
          <template #table-busy><Loader /></template>
          <template #table-colgroup="scope">
            <col
              v-for="field in scope.fields"
              :key="field.key"
              :style="{ ...field.style, width: field.width || 'auto' }"
              class="text-right"
            />
          </template>
          <template #cell(CategoryId)="{ item: ndf, value }">
            {{ value }}
            <span v-if="isNdfInConflict(ndf)" v-b-tooltip.hover="tooltipConflict">
              <fa-icon
                icon="exclamation-triangle"
                class="ml-auto text-warning btn-icon"
              />
            </span>
          </template>
          <template #cell(Description)="{ value: { description, client } }">
            <p v-if="description" class="mb-0">{{ description }}</p>
            <p v-if="client" class="mb-0">
              <b-badge variant="info">CLIENT : {{ client }}</b-badge>
            </p>
          </template>
          <template #cell(exclAmount)="{ value: { raw, refund } }">
            <del v-if="raw !== refund" :class="delClass" v-html="raw"></del>
            <span v-html="refund"></span>
          </template>
          <template #cell(vatAmount)="{ value: { raw, refund } }">
            <del v-if="raw !== refund" :class="delClass" v-html="raw"></del>
            <span v-html="refund"></span>
          </template>
          <template #cell(inclAmount)="{ value: { raw, refund } }">
            <del v-if="raw !== refund" :class="delClass" v-html="raw"></del>
            <span v-html="refund"></span>
          </template>
          <template #cell(status)="{ value }">
            <div v-b-tooltip.hover :title="value.text" class="text-center">
              <fa-icon :icon="value.icon" :class="'text-' + value.color" />
            </div>
          </template>
          <template #cell(actions)="row">
            <div class="text-center">
              <span
                v-if="adminMode"
                v-b-tooltip.hover
                :title="!row.detailsShowing ? 'Détails' : 'Masquer'"
                class="mr-2"
              >
                <fa-icon
                  :icon="!row.detailsShowing ? 'chevron-down' : 'chevron-up'"
                  class="text-primary btn-icon"
                  @click="onToggleRow(row)"
                />
              </span>
              <span
                v-else
                v-b-tooltip.hover="
                  isReadOnly(row.item) ? tooltipEdit : tooltipDetails
                "
                class="mr-2"
              >
                <fa-icon
                  :icon="!isReadOnly(row.item) ? 'edit' : ['far', 'eye']"
                  :class="processing ? 'text-muted' : 'text-primary btn-icon'"
                  @click="onEditClick(row.item)"
                />
              </span>
              <span
                v-b-tooltip.hover="tooltipDelete"
                :class="{ invisible: !isDeletable(row.item) }"
              >
                <fa-icon
                  icon="trash-alt"
                  :class="processing ? 'text-muted' : 'text-danger btn-icon'"
                  @click="onDeleteClick(row.item)"
                />
              </span>
            </div>
          </template>
          <template #row-details="{ item }">
            <b-card bg-variant="light">
              <NdfRowDetails
                :ndf="item"
                :categories="categories"
                @ndfcat-changed="onCategoryChanged"
                @error-raised="handleError"
              />
            </b-card>
          </template>
        </b-table>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { computed, ref } from 'vue'
import { mapActions } from 'pinia'
import { useStore } from '@/stores'
import { LOAD_CLIENTS, LOAD_PROJECTS } from '@/stores/action-types'
import { StringMixin, VatMixin } from '@/mixins'
import { NdfService } from '@/services'
import Loader from '@/components/Loader.vue'
import Camera from '@/components/Tool/Camera.vue'
import NdfDetails from '@/components/Ndf/NdfDetails.vue'
import NdfRowDetails from '@/components/Ndf/NdfRowDetails.vue'
import { NdfStatus } from '@/assets/js/Constants.js'
import { Ndf } from '@/types'
import moment from 'moment'
import { MODE } from '@/assets/js/Constants'
import { useAdal } from '@/composables/useAdal'
import { useNdf } from '@/composables/useNdf'
import { useRemoteActivity } from '@/composables/useRemoteActivity'
import { useTooltips } from '@/composables/useTooltips'

export default {
  name: 'Ndf',
  components: { Loader, Camera, NdfDetails, NdfRowDetails },
  mixins: [StringMixin, VatMixin],
  data() {
    return {
      ndfs: [],
      cameraReady: false,
      currentNdf: new Ndf(),
      mode: MODE.CREATE,
      search: '',
      loadingStatuses: {
        ndfs: false,
        categories: false,
        clients: false,
        projects: false
      },
      filterIncluded: [
        'Date',
        'Upn',
        'CategoryId',
        'Description',
        'exclAmount',
        'vatAmount',
        'inclAmount',
        'Status'
      ],
      alerts: {
        success: {
          countdown: 0
        },
        failure: {
          countdown: false,
          reason: String
        }
      },
      export: {
        icon: 'spinner',
        variant: undefined,
        status: undefined,
        message: undefined,
        csv: {
          icon: 'spinner',
          variant: undefined,
          message: undefined
        },
        pdf: {
          icon: 'spinner',
          variant: undefined,
          message: undefined
        },
        isClosing: false
      },
      perPage: 25,
      currentPage: 1,
      filteredRowCount: -1,
      slice: moment().format('YYYY-MM'),
      processing: false,
      delClass: 'text-info small mr-1'
    }
  },
  setup() {
    const { isAdmin } = useAdal()
    const adminMode = ref(false)
    const isAdminMode = computed(() => isAdmin() && adminMode.value)
    const {
      categories,
      loadNdfCategories,
      splitDescription,
      isNdfInConflict
    } = useNdf()
    const { loadMyRemoteActivity, loadAllRemoteActivity } = useRemoteActivity(isAdminMode.value)
    const loadRemoteActivity = () => isAdminMode.value
        ? loadAllRemoteActivity()
        : loadMyRemoteActivity()
    const { tooltipConflict: tooltipConflictConfig } = useTooltips()

    return {
      isAdmin,
      adminMode,
      isAdminMode,
      categories,
      loadNdfCategories,
      splitDescription,
      isNdfInConflict,
      loadRemoteActivity,
      tooltipConflictConfig
    }
  },
  computed: {
    sliceM: {
      get() {
        return this.slice.substring(5)
      },
      set(value) {
        this.slice = `${this.sliceY}-${value}`
      }
    },
    sliceY: {
      get() {
        return this.slice.substring(0, 4)
      },
      set(value) {
        this.slice = `${value}-${this.sliceM}`
        this.sliceM = this.slicesM[0].value
      }
    },
    slicesM() {
      return this.slices
        .filter(s => s.year == this.sliceY)
        .map(s => ({
          value: s.month,
          text: s.text.split(' ')[0]
        }))
    },
    slicesY() {
      return this.slices.reduce((p, c) => {
        if (p.indexOf(c.year) == -1) {
          p.push(c.year)
        }

        return p
      }, [])
    },
    slices() {
      var start = moment('2019-12').locale('fr)')
      var count = Math.ceil(moment().diff(start, 'month', true))

      return Array.apply(undefined, new Array(count)).map((v, i) => {
        var m = moment(start).add(i, 'month')
        return {
          value: m.format('YYYY-MM'),
          text: m.format('MMM YYYY'),
          month: m.format('MM'),
          year: m.format('YYYY')
        }
      })
    },
    fields() {
      return [
        {
          key: 'Date',
          label: 'Date',
          formatter: this.formatDate,
          filterByFormatted: true,
          sortable: true,
          width: '105px'
        },
        this.adminMode
          ? {
              key: 'Upn',
              label: 'E/S',
              sortable: true
            }
          : undefined,
        {
          key: 'CategoryId',
          label: 'Categorie',
          formatter: this.formatCategoryId,
          filterByFormatted: true,
          sortable: true,
          thClass: {
            'd-none': this.adminMode,
            'd-lg-table-cell': this.adminMode
          },
          tdClass: {
            'd-none': this.adminMode,
            'd-lg-table-cell': this.adminMode
          }
        },
        !this.adminMode 
          ? {
              key: 'Description',
              formatter: this.formatDescription,
              sortable: true,
              thClass: 'd-none d-lg-table-cell',
              tdClass: 'd-none d-lg-table-cell'
            }
          : undefined,
        {
          key: 'exclAmount',
          label: 'HT',
          formatter: (v, k, item) => this.formatExclAmount(item),
          sortable: true,
          filterByFormatted: true, //(v, k, item) => this.getExclAmount(item.VatItems),
          sortByFormatted: (v, k, item) => this.getExclAmount(item.VatItems),
          thClass: 'd-none d-md-table-cell',
          tdClass: 'text-right d-none d-md-table-cell',
          width: '80px'
        },
        {
          key: 'vatAmount',
          label: 'TVA',
          formatter: (v, k, item) => this.formatVatAmount(item),
          filterByFormatted: true, //(v, k, item) => this.getVatAmount(item.VatItems),
          sortable: true,
          sortByFormatted: (v, k, item) => this.getVatAmount(item.VatItems),
          thClass: 'd-none d-md-table-cell',
          tdClass: 'text-right d-none d-md-table-cell',
          width: '80px'
        },
        {
          key: 'inclAmount',
          label: 'TTC',
          formatter: (v, k, item) => this.formatInclAmount(item),
          filterByFormatted: true, //(v, k, item) => this.getInclAmount(item.VatItems),
          sortable: true,
          sortByFormatted: (v, k, item) => this.getInclAmount(item.VatItems),
          thClass: 'd-none d-md-table-cell',
          tdClass: 'text-right d-none d-md-table-cell',
          width: '80px'
        },
        {
          key: 'Status',
          label: 'Statut',
          formatter: v => this.getStatusAssets(v),
          filterByFormatted: this.formatStatus,
          sortable: true,
          width: '80px'
        },
        {
          key: 'actions',
          label: 'Actions',
          width: '85px'
        }
      ].filter(f => f !== undefined)
    },
    isCreateMode() {
      return this.mode == MODE.CREATE
    },
    loading() {
      const { ndfs, categories } = this.loadingStatuses

      return ndfs || categories
    },
    pictureTaken() {
      return !!this.currentNdf.Photo
    },
    isNdfReadOnly() {
      return this.isReadOnly(this.currentNdf)
    },
    endBoundary() {
      const endBoundary = moment().locale('fr')

      if (this.isClosing) {
        endBoundary.startOf('day')
      } else {
        endBoundary.startOf('month').subtract(1, 'day')
      }

      return endBoundary.format('D MMMM YYYY')
    },
    totalRows() {
      return this.filteredRowCount < 0
        ? this.ndfs.length
        : this.filteredRowCount
    },
    failureToastVisible: {
      get() {
        return !!this.alerts.failure.countdown
      },
      set() {
        this.alerts.failure.countdown = false
      }
    },
    isClosing: {
      get() {
        return this.export.isClosing
      },
      set(value) {
        this.export.isClosing = value
      }
    },
    tooltipConfig() {
      return {
        disabled: this.processing
      }
    },
    tooltipEdit() {
      return {
        ...this.tooltipConfig,
        title: 'Editer'
      }
    },
    tooltipConflict() {
      return {
        ...this.tooltipConfig,
        ...this.tooltipConflictConfig
      }
    },
    tooltipDetails() {
      return {
        ...this.tooltipConfig,
        title: 'Voir'
      }
    },
    tooltipDelete() {
      return {
        ...this.tooltipConfig,
        title: 'Supprimer'
      }
    }
  },
  watch: {
    '$route.name': function () {
      this.setAdminMode()
      this.loadNdfs()
      this.loadRemoteActivity()
    }
  },
  mounted() {
    this.setAdminMode()
    this.loadNdfs()
    this.loadCategories()
    this.loadClients()
    this.loadProjects()
    this.loadRemoteActivity()
  },
  methods: {
    ...mapActions(useStore, [LOAD_CLIENTS, LOAD_PROJECTS]),
    setAdminMode() {
      this.adminMode = this.$route.name == 'ndf-validation'
    },
    // TODO: Mixin?
    formatCurrency(amount) {
      return `${amount.toFixed(2).replace('.00', '')}&nbsp;&euro;` // TODO: &nbsp;&euro;
    },
    formatCategoryId(id) {
      const c = this.categories.find(c => c.Id == id)

      return c ? c.Name : 'Inconnue'
    },
    formatDescription(value) {
      return this.splitDescription(value)
    },
    formatDate(date) {
      return new Date(date).toLocaleDateString('fr-FR')
    },
    formatExclAmount(item) {
      return this.formatAmount(item, this.getExclAmount)
    },
    formatVatAmount(item) {
      return this.formatAmount(item, this.getVatAmount)
    },
    formatInclAmount(item) {
      return this.formatAmount(item, this.getInclAmount)
    },
    formatAmount(item, getAmount) {
      const amount = getAmount(item.VatItems)
      const refundRate = item.PhoneUsage === 0 ? 0.5 : 1

      return {
        raw: this.formatCurrency(amount),
        refund: this.formatCurrency(amount * refundRate)
      }
    },
    formatStatus(status) {
      return this.getStatusAssets(status).text
    },
    isReadOnly({ Status }) {
      return Status == NdfStatus.Validated || Status == NdfStatus.Payed
    },
    isDeletable({ Status }) {
      return Status != NdfStatus.Validated && Status != NdfStatus.Payed
    },
    getStatusAssets(status) {
      switch (status) {
        case NdfStatus.Pending:
          return {
            text: 'En attente de validation',
            icon: ['far', 'meh'],
            color: 'secondary'
          }
        case NdfStatus.Validated:
          return {
            text: 'Validée',
            icon: ['far', 'grin-stars'],
            color: 'success'
          }
        case NdfStatus.Rejected:
          return {
            text: 'Rejetée',
            icon: ['far', 'frown'],
            color: 'danger'
          }
        case NdfStatus.Payed:
          return {
            text: 'Payée',
            icon: 'euro-sign',
            color: 'gold'
          }
      }
    },
    formatVatAmountDetails(vat) {
      const inclOrExcl = vat.Included ? 'TTC' : 'HT'

      return `${vat.Amount} ${inclOrExcl} (${vat.Rate * 100}%)`
    },
    onReadyStateChanged(...args) {
      console.debug('[NDF::onReadyStateChanged]: ', args)
      this.cameraReady = args[0]
    },
    loadNdfs() {
      this.loadingStatuses.ndfs = true

      const prom = this.isAdminMode
        ? NdfService.GetAllNdfs(this.slice)
        : NdfService.GetMyNdfs(this.slice)

      prom
        .then(response => {
          this.ndfs = response.data
          this.filteredRowCount = -1
        })
        .catch(this.handleError)
        .finally(() => {
          this.loadingStatuses.ndfs = false
        })
    },
    fillNdfDocument(ndf) {
      return NdfService.GetNdfDocument(ndf.Id)
        .then(doc => {
          ndf.DocumentContentType = doc.contentType
          ndf.Photo = window.URL.createObjectURL(
            new Blob([doc.content], { type: doc.contentType })
          )
        })
        .catch(err => {
          this.handleError(
            new Error(
              `Erreur lors de la récupération de l'image: ${err.message}`
            )
          )
        })
    },
    loadCategories() {
      this.loadingStatuses.categories = true

      this.loadNdfCategories()
        .catch(this.handleError)
        .finally(() => {
          this.loadingStatuses.categories = false
        })
    },
    loadClients() {
      this.loadingStatuses.clients = true

      this[LOAD_CLIENTS]()
        .catch(err => {
          console.error('There was an error: ', err)
        })
        .finally(() => {
          this.loadingStatuses.clients = false
        })
    },
    loadProjects() {
      this.loadingStatuses.projects = true

      this[LOAD_PROJECTS]()
        .catch(err => {
          console.error('There was an error: ', err)
        })
        .finally(() => {
          this.loadingStatuses.projects = false
        })
    },
    async onEditClick(ndf) {
      if (ndf) {
        if (!ndf.Photo) {
          await this.fillNdfDocument(ndf)
        }

        this.currentNdf = Ndf.clone(ndf)
        this.mode = MODE.EDIT
      } else {
        this.currentNdf = new Ndf()
        this.mode = MODE.CREATE
      }

      this.$refs.ndfModal.show()
    },
    onNdfValidated(evt) {
      evt.preventDefault()

      if (!this.processing && this.$refs.details.validate()) {
        this.processing = true

        const prom = this.isCreateMode
          ? NdfService.CreateNdf(this.currentNdf).then(response => {
              this.currentNdf.Id = response.data.Id
              this.ndfs.push(this.currentNdf)
            })
          : NdfService.SetNdf(this.currentNdf).then(() => {
              this.replaceNdf(this.currentNdf)
            })

        prom
          .then(() => this.$nextTick(() => this.$refs.ndfModal.hide()))
          .catch(this.handleError)
          .finally(() => (this.processing = false))
      }
    },
    onDeleteClick(ndf) {
      // Source: Table row click (otherwise Modal)
      if (ndf) {
        this.currentNdf = ndf

        this.deleteNdf()
      }
    },
    deleteNdf() {
      if (!this.processing && this.isDeletable(this.currentNdf)) {
        this.processing = true

        NdfService.DeleteNdf(this.currentNdf.Id)
          .then(() => {
            const idx = this.ndfs.findIndex(ndf => ndf.Id == this.currentNdf.Id)

            if (idx > -1) {
              const deleted = this.ndfs.splice(idx, 1)

              // TODO: Free Blob up for garbage collection
              if (deleted[0].Photo) {
                URL.revokeObjectURL(deleted[0].Photo)
              }
            }

            this.$nextTick(() => {
              this.$refs.ndfModal.hide()
              this.currentNdf = new Ndf()
            })
          })
          .catch(this.handleError)
          .finally(() => (this.processing = false))
      }
    },
    onToggleRow(row) {
      if (!row.detailsShowing && !row.item.Photo) {
        this.fillNdfDocument(row.item)
      }

      row.toggleDetails()
    },
    showFailure(err) {
      let message = ''

      if (err.response) {
        // Request OK but Status != 2xx
        message = err.response.data.Message
      } else {
        // Network Error
        message = err.message
      }

      this.alerts.failure.reason = message
      this.alerts.failure.countdown = true
    },
    onExportClick() {
      this.$refs.exportModal.show()
    },
    onConfirmExport(evt, force = false) {
      evt.preventDefault()

      this.export.icon = 'spinner'
      this.export.variant = 'secondary'
      this.export.status = 'en cours...'
      this.export.message = ''
      this.export.csv.icon = 'spinner'
      this.export.csv.variant = 'secondary'
      this.export.csv.message = ''
      this.export.pdf.icon = 'spinner'
      this.export.pdf.variant = 'secondary'
      this.export.pdf.message = ''

      Promise.all([
        NdfService.ExportCsv(this.isClosing, force)
          .then(result => {
            this.export.csv.icon = 'check-circle'
            this.export.csv.variant = 'success'
            this.export.csv.message = `${result.data.ndfCount} notes de frais exportées.`

            return true
          })
          .catch(err => {
            this.export.csv.icon = 'times-circle'
            this.export.csv.variant = 'danger'
            this.export.csv.message =
              !!err.response && !!err.response.data
                ? err.response.data
                : err.message

            return false
          }),
        NdfService.ExportPdf(this.isClosing, force)
          .then(result => {
            this.export.pdf.icon = 'check-circle'
            this.export.pdf.variant =
              result.data.exportCount > 0 ? 'success' : 'warning'
            this.export.pdf.message = `${result.data.exportCount} document(s) généré(s).`

            return true
          })
          .catch(err => {
            this.export.pdf.icon = 'times-circle'
            this.export.pdf.variant = 'danger'
            this.export.pdf.message =
              !!err.response && !!err.response.data
                ? err.response.data
                : err.message

            return false
          })
      ])
        .then(results => {
          if (!results[0] || !results[1]) {
            return Promise.reject()
          }

          return NdfService.MarkNdfAsPayed(this.isClosing).then(result => {
            this.export.icon = 'check-circle'
            this.export.variant =
              result.data.ndfCount > 0 ? 'success' : 'warning'
            this.export.status = 'réussie.'
            this.export.message = `${result.data.ndfCount} notes de frais traitées.`
          })
        })
        .catch(() => {
          this.export.icon = 'times-circle'
          this.export.variant = 'danger'
          this.export.status = 'échouée.'
        })
    },
    onForceExport(evt) {
      this.onConfirmExport(evt, true)
    },
    onCategoryChanged(ndf) {
      NdfService.ChangeNdfCategory(ndf.Id, ndf.CategoryId)
        .then(() => {
          this.replaceNdf(ndf)
        })
        .catch(this.handleError)
    },
    replaceNdf(ndf) {
      const idx = this.ndfs.findIndex(n => n.Id == ndf.Id)

      this.ndfs.splice(idx, 1, ndf)
    },
    onFiltered(items, count) {
      this.filteredRowCount = count
    },
    handleError(err) {
      this.showFailure(
        err.response
          ? new Error(err.response.data.Message, { cause: err })
          : err
      )
    }
  }
}
</script>
<style lang="scss" scoped>
.text-gold {
  color: #d4af37;
}
</style>
