export const SAVE_USER_PROFILE = 'SAVE_USER_PROFILE'
export const SAVE_USER_PROFILE_PRO = 'SAVE_USER_PROFILE_PRO'
export const SAVE_USER_ADMINISTRATIVE_INFO = 'SAVE_USER_ADMINISTRATIVE_INFO'
export const LOAD_USER_PROFILE = 'LOAD_USER_PROFILE'
export const PUT_MY_SKILL = 'PUT_MY_SKILL'
export const DELETE_MY_SKILL = 'DELETE_MY_SKILL'
export const GET_ALL_SKILLS = 'GET_ALL_SKILLS'
export const LOAD_CLIENTS = 'LOAD_CLIENTS'
export const DELETE_CLIENT = 'DELETE_CLIENT'
export const LOAD_PROJECTS = 'LOAD_PROJECTS'
export const DELETE_PROJECT = 'DELETE_PROJECT'
export const LOAD_USERS = 'LOAD_USERS'
export const DELETE_USER = 'DELETE_USER'
export const LOAD_NOTIFICATIONS = 'LOAD_NOTIFICATIONS'
export const LOAD_NDF_CATEGORIES = 'LOAD_NDF_CATEGORIES'
