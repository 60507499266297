import { reactive } from "vue";
import moment from 'moment'

export const remoteActivityOnCalendar = Symbol('remoteActivityOnCalendar')

const features = {
  [remoteActivityOnCalendar]: process.env.VUE_APP_FEATURES_REMOTE_ACTIVITY_ON_CALENDAR
}

export const useFeature = (featureKey) => {
  const enabled = features[featureKey] !== undefined

  if (featureKey === remoteActivityOnCalendar) {
    return reactive({
      enabled,
      isRemoteFeatureActive: date =>
        enabled && moment.utc(date).isSameOrAfter(moment.utc(features[featureKey]), 'day')
    })
  }

  return reactive({ enabled })
}
