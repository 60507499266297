<template>
  <UserInfoBox
    box-label="Informations de contact"
    :loading="loading"
    @user-saved="onUserSaved($event)"
    @user-not-saved="onUserNotSaved($event)"
  >
    <b-form-group
      id="jobTitleGroup"
      label="Intitulé du poste"
      label-for="jobTitle"
      label-cols-sm="4"
      label-cols-lg="3"
      :class="{ 'table-warning': !jobTitle }"
    >
      <b-form-input id="jobTitle" type="text" v-model="jobTitle" required />
    </b-form-group>
    <b-form-group
      id="emailAddressGroup"
      label="Adresse e-mail"
      label-for="emailAddress"
      label-cols-sm="4"
      label-cols-lg="3"
      :class="{ 'table-warning': !emailAddress }"
    >
      <b-form-input
        id="emailAddress"
        type="email"
        v-model="emailAddress"
        required
      />
    </b-form-group>
    <b-form-group
      id="phoneNumberGroup"
      label="Téléphone"
      label-for="phoneNumber"
      label-cols-sm="4"
      label-cols-lg="3"
      :class="{ 'table-warning': !phoneNumber }"
    >
      <b-form-input
        id="phoneNumber"
        type="tel"
        v-model="phoneNumber"
        required
      />
    </b-form-group>
    <b-form-group label="Adresse" label-size="lg">
      <b-form-group
        id="streetGroup"
        label="Rue"
        label-for="streetName"
        label-cols-sm="4"
        label-cols-lg="3"
        :class="{ 'table-warning': !streetName }"
      >
        <b-form-input
          id="streetName"
          type="text"
          v-model="streetName"
          required
        />
      </b-form-group>
      <b-form-group
        id="streetGroup"
        label="Code Postal"
        label-for="zipCode"
        label-cols-sm="4"
        label-cols-lg="3"
        :class="{ 'table-warning': !zipCode }"
      >
        <b-form-input
          id="zipCode"
          type="text"
          v-model="zipCode"
          required
          pattern="\d{5}"
        />
      </b-form-group>
      <b-form-group
        id="cityGroup"
        label="Ville"
        label-for="city"
        label-cols-sm="4"
        label-cols-lg="3"
        :class="{ 'table-warning': !city }"
      >
        <b-form-input id="city" type="text" v-model="city" required />
      </b-form-group>
      <b-form-group
        id="countryGroup"
        label="Pays"
        label-for="country"
        label-cols-sm="4"
        label-cols-lg="3"
        :class="{ 'table-warning': !country }"
      >
        <b-form-input id="country" type="text" v-model="country" required />
      </b-form-group>
    </b-form-group>
  </UserInfoBox>
</template>
<script>
import { mapState, mapActions } from 'pinia'
import { useStore } from '@/stores'
import { USER_PROFILE } from '@/stores/state-keys'
import {
  SET_USER_JOB_TITLE,
  SET_USER_EMAIL_ADDRESS,
  SET_USER_PHONE_NUMBER,
  SET_USER_STREET_NAME,
  SET_USER_ZIP_CODE,
  SET_USER_CITY,
  SET_USER_COUNTRY
} from '@/stores/mutation-types'
import UserInfoBox from '@/components/User/UserInfoBox.vue'

export default {
  name: 'ContactInfoBox',
  components: { UserInfoBox },
  props: {
    loading: { type: Boolean, default: false }
  },
  computed: {
    ...mapState(useStore, [USER_PROFILE]),
    jobTitle: {
      get() {
        return this[USER_PROFILE].JobTitle
      },
      set(value) {
        this[SET_USER_JOB_TITLE](value)
      }
    },
    emailAddress: {
      get() {
        return this[USER_PROFILE].EmailAddress
      },
      set(value) {
        this[SET_USER_EMAIL_ADDRESS](value)
      }
    },
    phoneNumber: {
      get() {
        return this[USER_PROFILE].PhoneNumber
      },
      set(value) {
        this[SET_USER_PHONE_NUMBER](value)
      }
    },
    streetName: {
      get() {
        return this[USER_PROFILE].Address.StreetName
      },
      set(value) {
        this[SET_USER_STREET_NAME](value)
      }
    },
    zipCode: {
      get() {
        return this[USER_PROFILE].Address.ZipCode
      },
      set(value) {
        this[SET_USER_ZIP_CODE](value)
      }
    },
    city: {
      get() {
        return this[USER_PROFILE].Address.City
      },
      set(value) {
        this[SET_USER_CITY](value)
      }
    },
    country: {
      get() {
        return this[USER_PROFILE].Address.Country
      },
      set(value) {
        this[SET_USER_COUNTRY](value)
      }
    }
  },
  methods: {
    ...mapActions(useStore, [
      SET_USER_JOB_TITLE,
      SET_USER_EMAIL_ADDRESS,
      SET_USER_PHONE_NUMBER,
      SET_USER_STREET_NAME,
      SET_USER_ZIP_CODE,
      SET_USER_CITY,
      SET_USER_COUNTRY
    ]),
    onUserSaved(event) {
      this.$emit('user-saved', event)
    },
    onUserNotSaved(event) {
      this.$emit('user-not-saved', event)
    }
  }
}
</script>
<style scoped>
h4 {
  text-align: left;
}
</style>
