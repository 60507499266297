<template>
  <b-form ref="form" :validated="validated">
    <b-form-row>
      <b-col>
        <b-form-group label="Granularité" label-cols-sm="3" content-cols-sm="9">
          <b-form-radio-group
            v-model="allDayOption"
            button-variant="outline-primary"
            size="sm"
            class="mt-1 w-100"
            @change="allDayOptionChanged"
            buttons
          >
            <b-form-radio
              v-for="{ text, value } in allDayOptions"
              :key="value"
              :value="value"
              class="col-4"
              >{{ text }}</b-form-radio
            >
          </b-form-radio-group>
        </b-form-group>
      </b-col>
    </b-form-row>
    <b-form-row>
      <b-col>
        <b-form-group label="Date" class="mb-0">
          <b-input-group>
            <date-picker
              ref="sdPicker"
              v-model="startDate"
              :config="rxConfig.picker.date"
              required
            />
            <date-picker
              ref="stPicker"
              v-if="!isAllDay"
              v-model="startTime"
              :config="rxConfig.picker.time"
              class="col-5"
              :required="!isAllDay"
            />
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col cols="5">
        <b-form-group label="Durée" class="mb-0">
          <b-input-group>
            <b-form-spinbutton
              v-model="duration"
              :min="rxConfig.spin.step"
              :max="rxConfig.spin.max"
              :step="rxConfig.spin.step"
              :formatter-fn="formatDurationUnit"
              :state="durationPickerState"
              :disabled="isHalfDay"
              required
              inline
            />
          </b-input-group>
        </b-form-group>
      </b-col>
      <h6 class="form-text text-muted text-center w-100 mb-3">
        Imputation de
        <b-badge variant="info">{{ timespanDuration }}</b-badge
        >, {{ timespanArticle }}
        <b-badge variant="info">{{ timespanDateStart }}</b-badge>
        <template v-if="isAllDay && duration > 1">
          au <b-badge variant="info">{{ timespanDateEnd }}</b-badge>
        </template>
        <template v-else-if="!isAllDay">
          {{ timespanString }}
        </template>
      </h6>
    </b-form-row>
    <b-form-row>
      <b-col>
        <b-form-group label="Projet">
          <LiveSearchSelect
            v-model="timeslot.projectId"
            :items="filteredProjectsOptions"
            :options="internalProjectsOptions"
            required
          >
            <template #list-item="{ text, altText }">
              {{ text }}
              <template v-if="altText">
                <br /><small>{{ altText }}</small>
              </template>
            </template>
          </LiveSearchSelect>
        </b-form-group>
      </b-col>
    </b-form-row>
    <b-form-row>
      <b-col>
        <b-form-group label="Description de la mission">
          <b-form-textarea v-model="timeslot.description" />
        </b-form-group>
      </b-col>
    </b-form-row>
    <b-form-row v-if="showRemoteCheckbox">
      <b-col>
        <b-form-group label="Télétravail" label-cols="3">
          <b-checkbox v-model="timeslot.remote" size="lg" />
        </b-form-group>
      </b-col>
    </b-form-row>
  </b-form>
</template>

<script>
import { computed } from 'vue'
import moment from 'moment'
import { DateTimePickerConfigMixin, ProjectMixin } from '@/mixins'
import { mapState } from 'pinia'
import { useStore } from '@/stores'
import {
  CLIENT_NAME,
  PROJECT,
  PROJECTS,
  INTERNAL_PROJECTS
} from '@/stores/getter-types.js'
import { isEmpty } from '@/stores/utils'
import LiveSearchSelect from '@/components/Tool/LiveSearchSelect.vue'
import { useFeature, remoteActivityOnCalendar } from '@/composables/useFeature'

const AD_OPTIONS = Object.freeze({
  ALL_DAY: 'allDay',
  HALF_DAY: 'halfDay',
  CUSTOM: 'custom'
})

const parseUtcDate = dateStr => {
  const [date, month, year] = dateStr.split('/')

  return moment
    .utc()
    .year(year)
    .month(month - 1)
    .date(date)
    .toDate()
}

export default {
  name: 'TimeslotDetails',
  components: { LiveSearchSelect },
  mixins: [DateTimePickerConfigMixin, ProjectMixin],
  props: {
    timeslot: { type: Object, required: true },
    isCreateMode: Boolean
  },
  data() {
    return {
      validated: false,
      initialProjectId: null,
      initialDuration: null,
      durationPickerState: null,
      durationDays: 1,
      durationHours: 1,
      errorMessage: '',
      startDate: this.getDatePart(this.timeslot.start),
      startTime: this.getTimePart(this.timeslot.start),
      endDate: this.getDatePart(this.timeslot.end),
      endTime: this.getTimePart(this.timeslot.end),
      allDayOption: AD_OPTIONS.ALL_DAY,
      allDayOptions: [
        { text: 'Journée(s) (8h)', value: AD_OPTIONS.ALL_DAY },
        { text: '½ journée (4h)', value: AD_OPTIONS.HALF_DAY },
        { text: 'Autre', value: AD_OPTIONS.CUSTOM }
      ]
    }
  },
  setup(props) {
    const { isRemoteFeatureActive } = useFeature(remoteActivityOnCalendar)
    const showRemoteCheckbox = computed(() => {
      return !isRemoteFeatureActive(props.timeslot.start)
    })

    return { showRemoteCheckbox }
  },
  computed: {
    ...mapState(useStore, [CLIENT_NAME, PROJECT, PROJECTS, INTERNAL_PROJECTS]),
    isAllDay() {
      return this.timeslot.allDay
    },
    isHalfDay() {
      return this.allDayOption == AD_OPTIONS.HALF_DAY
    },
    filteredProjectsOptions() {
      const start = parseUtcDate(this.startDate)
      const end = parseUtcDate(this.endDate)

      return this[PROJECTS].filter(
        p =>
          !isEmpty(p.ClientId) &&
          ((!p.StartDate && !p.EndDate) ||
            (new Date(p.EndDate) >= start && new Date(p.StartDate) <= end))
      ).map(p => ({
        value: p.Id,
        text: `${(name => (name ? `${name} - ` : ''))(
          this[CLIENT_NAME](p.ClientId)
        )}${this.formatProjectName(p)}`,
        altText: this.formatProjectAltText(p),
        disabled: p.DayCount && p.Consumed >= p.DayCount,
        ...p
      }))
    },
    internalProjectsOptions() {
      return this[INTERNAL_PROJECTS].map(p => {
        const [text, altText] = p.Name.replace('-', '').trim().split(' - ')

        return {
          value: p.Id,
          text,
          altText,
          disabled: p.DayCount && p.Consumed >= p.DayCount,
          ...p
        }
      })
    },
    rxConfig() {
      const { allDay, start } = this.timeslot
      const end = start.clone().add(1, 'month').startOf('month')

      const spin = allDay
        ? {
            max: end.diff(start, 'days'),
            step: 1
          }
        : {
            max: 7.75,
            step: 0.25
          }

      return {
        picker: this.configs.picker,
        spin
      }
    },
    duration: {
      get() {
        return this.isAllDay ? this.durationDays : this.durationHours
      },
      set(value) {
        if (this.isAllDay) {
          this.durationDays = value
        } else {
          this.durationHours = value
        }

        this.computeEndDate()
      }
    },
    timespanDuration() {
      let [duration, unit] = this.isAllDay
        ? [this.durationDays, 'jour']
        : this.durationHours % 2 == 0
        ? [this.durationHours / 8, 'jour']
        : [this.durationHours, 'heure']

      unit += duration > 1 ? 's' : ''

      return `${duration} ${unit}`
    },
    timespanString() {
      const { start, end } = this.timeslot

      return `de ${this.formatTime(start)} à ${this.formatTime(end)}`
    },
    timespanArticle() {
      return this.isAllDay && this.duration > 1 ? 'du' : 'le'
    },
    timespanDateStart() {
      return this.formatDate(this.timeslot.start)
    },
    timespanDateEnd() {
      return this.formatDate(
        this.timeslot.end.clone().add(-1, 'day').endOf('day')
      )
    }
  },
  watch: {
    startDate(val) {
      this.timeslot.start = this.setDatePart(this.timeslot.start, val)
      this.computeEndDate()
    },
    startTime(val) {
      this.timeslot.start = this.setTimePart(this.timeslot.start, val)
      this.computeEndDate()
    },
    endDate(val) {
      this.timeslot.end = this.setDatePart(this.timeslot.end, val)
    },
    endTime(val) {
      this.timeslot.end = this.setTimePart(this.timeslot.end, val)
    }
  },
  mounted() {
    this.clearErrors()

    const { allDay, start, end, projectId } = this.timeslot

    this.initialProjectId = projectId
    this.initialDuration = allDay
      ? end.diff(start, 'days', true)
      : end.diff(start, 'hours', true) / 8

    if (allDay) {
      this.durationDays = end.diff(start, 'days')
    } else {
      this.durationHours = end.diff(start, 'hours', true)
    }

    this.allDayOption = this.timeslot.allDay
      ? AD_OPTIONS.ALL_DAY
      : this.durationHours == 4
      ? AD_OPTIONS.HALF_DAY
      : AD_OPTIONS.CUSTOM
  },
  methods: {
    formatDurationUnit(value) {
      if (this.isAllDay) {
        return `${value} j`
      }

      const d = moment.duration(
        this.isAllDay ? { days: value } : { hours: value }
      )
      const hours = d.hours()
      const minutes = d.minutes()

      return !hours
        ? `${minutes} min`
        : !minutes
        ? `${hours} h`
        : `${hours} h ${minutes} min`
    },
    formatDate(moment) {
      return moment.format('dddd Do')
    },
    formatTime(moment) {
      return moment.minutes() == 0
        ? moment.format('H[h]')
        : moment.format('H[h]mm')
    },
    computeEndDate() {
      const { allDay, start } = this.timeslot
      let end = start.clone().add(this.duration, allDay ? 'days' : 'hours')

      this.endDate = this.getDatePart(end)
      this.endTime = this.getTimePart(end)
    },
    getDatePart(moment) {
      return moment ? moment.utc().format('DD/MM/YYYY') : undefined
    },
    setDatePart(moment, value) {
      if (moment) {
        const date = value.split('/')
        moment
          .utc()
          .year(date[2])
          .month(date[1] - 1)
          .date(date[0])
      }

      return moment.clone()
    },
    getTimePart(moment) {
      return moment ? moment.utc().format('HH:mm') : undefined
    },
    setTimePart(moment, value) {
      if (moment) {
        const time = value.split(':')
        moment.utc().hours(time[0]).minutes(time[1]).seconds(0).milliseconds(0)
      }

      return moment.clone()
    },
    clearErrors() {
      this.errorMessage = ''
      this.setDateValidity('')
      this.setProjectValidity('')
      this.durationPickerState = null
    },
    setDateValidity(msg) {
      this.$refs.sdPicker.$el.setCustomValidity(msg)
      this.durationPickerState = !msg ? null : false

      if (!this.timeslot.allDay) {
        this.$refs.stPicker.$el.setCustomValidity(msg)
      }
    },
    setProjectValidity(msg) {
      this.durationPickerState = !msg ? null : false
    },
    checkStartEnd() {
      const { start, end } = this.timeslot

      return start.isBefore(end)
    },
    checkMonthOverlap() {
      const { allDay, start, end } = this.timeslot
      let realEnd = allDay ? end.clone().add(-1, 'day').endOf('day') : end

      return !(
        start.month() != realEnd.month() || start.year() != realEnd.year()
      )
    },
    checkProjectRemainingDays() {
      const { start, end, projectId } = this.timeslot
      const project = this[PROJECT](projectId)

      if (!!project) {
        const duration = end.diff(start, 'days', true)
        let consumed = project.Consumed

        if (!this.isCreateMode && this.initialProjectId == projectId) {
          consumed -= this.initialDuration
        }

        return !project.DayCount || project.DayCount >= consumed + duration
      }

      return true
    },
    checkSlot() {
      this.clearErrors()

      let valid = this.$refs.form.checkValidity()

      if (!valid) {
        this.errorMessage = 'Veuillez renseigner les champs obligatoires.'
      }

      if (!this.checkStartEnd()) {
        const errorMessage =
          'La date de fin doit être postérieure à la date de début.'

        this.setDateValidity(errorMessage)
        this.errorMessage = errorMessage || errorMessage
      }

      if (!this.checkMonthOverlap()) {
        const errorMessage =
          'Une activité ne peut être à cheval sur plusieurs mois.'

        this.setDateValidity(errorMessage)
        this.errorMessage = this.errorMessage || errorMessage
      }

      if (!this.checkProjectRemainingDays()) {
        const errorMessage =
          'La durée dépasse le nombre de jours restant dans ce projet.'

        this.setProjectValidity(errorMessage)
        this.errorMessage = this.errorMessage || errorMessage
      }

      valid = this.$refs.form.checkValidity()

      this.durationPickerState = this.durationPickerState !== false
      this.validated = true

      return this.durationPickerState && valid
    },
    getErrorMessage() {
      return this.errorMessage
    },
    allDayOptionChanged(value) {
      if (this.timeslot.allDay && value != AD_OPTIONS.ALL_DAY) {
        this.startTime = '09:00'
      }

      this.timeslot.allDay = value == AD_OPTIONS.ALL_DAY

      switch (value) {
        case AD_OPTIONS.ALL_DAY: {
          const { start, end } = this.timeslot

          start.startOf('day')
          end.startOf('day')

          this.startDate = this.getDatePart(start)
          this.startTime = this.getTimePart(start)
          this.endDate = this.getDatePart(end)
          this.endTime = this.getTimePart(end)

          break
        }
        case AD_OPTIONS.HALF_DAY:
          this.durationHours = 4

          break
      }
    }
  }
}
</script>
