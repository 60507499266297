import * as Mutations from './mutation-types'
import * as Actions from './action-types'
import mutations from './mutations'
import { USERS } from './state-keys'
import { UserService, ClientService, NdfService, ProjectService } from '@/services'

const actions = {
  ...mutations,
  [Actions.SAVE_USER_PROFILE](profile) {
    return UserService.SetUserProfile(profile)
      .then(response => {
        const retUser = response.data
        const retDocs = retUser.Documents

        this[Mutations.SET_USER_PHOTO](retUser.Photo)
        this[Mutations.SET_USER_IDENTITY_CARD](retDocs.Identity)
        this[Mutations.SET_USER_HEALTH_INSURANCE_EVIDENCE](
          retDocs.HealthInsuranceEvidence
        )
        this[Mutations.SET_USER_CURRICULUM_VITAE](retDocs.CurriculumVitae)
        this[Mutations.SET_USER_BANK_STATEMENT](retDocs.BankStatement)
      })
      .catch(error => {
        console.error(
          '[STORE] Uncaught error while saving user profile: ',
          error
        )

        throw error
      })
  },
  [Actions.SAVE_USER_PROFILE_PRO]({
    IsAvailable,
    ShortBio,
    Adr,
    PreferredLocation
  }) {
    return UserService.SetUserProfilePro({
      IsAvailable,
      ShortBio,
      Adr,
      PreferredLocation
    }).catch(error => {
      console.error(
        '[STORE] Uncaught error while saving user PRO profile: ',
        error
      )

      throw error
    })
  },
  [Actions.SAVE_USER_ADMINISTRATIVE_INFO]({ upn, account, tiers, analytique }) {
    return UserService.SetUserAdministrativeInfo(upn, {
      Compte: account,
      tiers,
      analytique
    }).catch(error => {
      console.error(
        '[STORE] Uncaught error while saving user administrative info: ',
        error
      )

      throw error
    })
  },
  [Actions.LOAD_USER_PROFILE]({ given_name, family_name, name }) {
    return UserService.GetUserProfile()
      .then(response => {
        const profile = response.data

        if (!profile.FirstName) profile.FirstName = given_name
        if (!profile.LastName) profile.LastName = family_name
        if (!profile.DisplayName) profile.DisplayName = name

        this[Mutations.SET_USER_PROFILE](profile)
      })
      .catch(error => {
        console.error(
          '[STORE] Uncaught error while loading user profile: ',
          error
        )

        throw error
      })
  },
  [Actions.PUT_MY_SKILL](skill) {
    return UserService.PutMySkill(skill)
      .then(() => {
        this[Mutations.PUSH_USER_SKILL](skill)
      })
      .catch(err => {
        console.error(
          '[STORE] Uncaught error while adding new user skills: ',
          err
        )

        throw err
      })
  },
  [Actions.DELETE_MY_SKILL](skill) {
    return UserService.DeleteMySkill(skill)
      .then(() => {
        this[Mutations.POP_USER_SKILL](skill)
      })
      .catch(err => {
        console.error(
          '[STORE] Uncaught error while deleting user skills: ',
          err
        )

        throw err
      })
  },
  [Actions.GET_ALL_SKILLS]() {
    return UserService.GetAllSkills()
      .then(response => {
        const skills = response.data

        this[Mutations.SET_ALL_SKILLS](skills)
      })
      .catch(err => {
        console.error(
          '[STORE] Uncaught error while retrieving all skills: ',
          err
        )

        throw err
      })
  },
  [Actions.LOAD_CLIENTS]() {
    return ClientService.GetClients()
      .then(response => this[Mutations.SET_CLIENTS](response.data))
      .catch(err => {
        console.error('[STORE] Uncaught error while retrieving clients: ', err)

        throw err
      })
  },
  [Actions.DELETE_CLIENT](id) {
    return ClientService.DeleteClient(id)
      .then(() => {
        this[Mutations.REMOVE_CLIENT](id)
      })
      .catch(err => {
        console.error('[STORE] Uncaught error while deleting client: ', err)

        throw err
      })
  },
  [Actions.LOAD_PROJECTS]() {
    return ProjectService.GetProjects()
      .then(response => this[Mutations.SET_PROJECTS](response.data))
      .catch(err => {
        console.error('[STORE] Uncaught error while retrieving projects: ', err)

        throw err
      })
  },
  [Actions.DELETE_PROJECT](id) {
    return ProjectService.DeleteProject(id)
      .then(() => {
        this[Mutations.REMOVE_PROJECT](id)
      })
      .catch(err => {
        console.error('[STORE] Uncaught error while deleting project: ', err)

        throw err
      })
  },
  [Actions.LOAD_USERS]() {
    return UserService.GetUsers()
      .then(response => this[Mutations.SET_USERS](response.data))
      .then(() =>
        this[USERS].forEach(u => {
          UserService.GetUserPhoto(u.Upn).then(response =>
            this[Mutations.SET_ES_PHOTO]({ upn: u.Upn, photo: response.data })
          )
        })
      )
      .catch(err => {
        console.error('[STORE] Uncaught error while retrieving users: ', err)

        throw err
      })
  },
  [Actions.DELETE_USER](upn) {
    return UserService.DeleteUser(upn)
      .then(() => {
        this[Mutations.REMOVE_USER](upn)
      })
      .catch(err => {
        console.error('[STORE] Uncaught error while deleting user: ', err)

        throw err
      })
  },
  [Actions.LOAD_NOTIFICATIONS]() {
    return UserService.GetNotifications()
      .then(response => this[Mutations.SET_NOTIFICATIONS](response.data))
      .catch(err => {
        console.error(
          '[STORE] Uncaught error while retrieving notifications: ',
          err
        )

        throw err
      })
  },
  [Actions.LOAD_NDF_CATEGORIES]() {
    return NdfService.GetCategories()
      .then(response => this[Mutations.SET_NDF_CATEGORIES](response.data))
      .catch(err => {
        console.error(
          '[STORE] Uncaught error while retrieving NDF categories: ',
          err
        )

        throw err
      })
  }
}

export default actions
