import { getCurrentInstance } from "vue"

export const useAdal = () => {
  // TODO: Use composable or injector
  const adal = getCurrentInstance().proxy.$adal

  return {
    isAdmin: () => adal.checkRoles(['Admin']),
    isSalariedEntrepreneur: () => adal.checkRoles(['ES_SALARIED'])
  }
}