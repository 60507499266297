import * as State from './state-keys'

const state = () => ({
  [State.USER_PROFILE]: {
    FirstName: '',
    LastName: '',
    JobTitle: '',
    HealthNumber: '',
    EmailAddress: '',
    PhoneNumber: '',
    Address: {
      StreetName: '',
      ZipCode: '',
      City: '',
      Country: ''
    },
    Photo: {
      FileName: undefined,
      Size: undefined,
      Content: undefined,
      Status: undefined
    },
    Documents: {
      Identity: {
        FileName: undefined,
        Size: undefined,
        Content: undefined,
        Status: undefined
      },
      HealthInsuranceEvidence: {
        FileName: undefined,
        Size: undefined,
        Content: undefined,
        Status: undefined
      },
      CurriculumVitae: {
        FileName: undefined,
        Size: undefined,
        Content: undefined,
        Status: undefined
      },
      BankStatement: {
        FileName: undefined,
        Size: undefined,
        Content: undefined,
        Status: undefined
      }
    },
    IsAvailable: false,
    ShortBio: '',
    Skills: [],
    Adr: undefined,
    PreferredLocation: ''
  },
  [State.ALL_SKILLS]: [],
  [State.CLIENTS]: [],
  [State.PROJECTS]: [],
  [State.USERS]: [],
  [State.NOTIFICATIONS]: {
    UserValidations: 0,
    NdfValidations: 0,
    NdfRejected: 0
  },
  [State.NDF_CATEGORIES]: []
})

export default state
