<template>
  <b-container id="home">
    <b-row>
      <b-col cols="12" sm="6" md="4" xl="3">
        <b-card header="Profil utilisateur">
          <b-progress
            :max="max"
            id="progress"
            height="2rem"
            class="mb-3"
            :animated="loading"
            show-progress
          >
            <b-progress-bar :value="score" :label="label"></b-progress-bar>
          </b-progress>
          <b-button block variant="primary" :to="{ name: 'profil' }"
            >{{ !!missingInfo ? 'Compléter' : 'Voir' }} le profil</b-button
          >
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { mapState, mapActions } from 'pinia'
import { useStore } from '@/stores'
import { MAX_SCORE, SCORE } from '@/stores/getter-types.js'
import { LOAD_USER_PROFILE } from '@/stores/action-types.js'

export default {
  name: 'Home',
  data() {
    return {
      alerts: {
        success: {
          countdown: 0
        },
        failure: {
          countdown: false,
          reason: String
        }
      },
      loading: false
    }
  },
  computed: {
    ...mapState(useStore, {
      profileScore: SCORE,
      max: MAX_SCORE
    }),
    label() {
      return this.loading
        ? 'Chargement...'
        : !this.missingInfo
        ? 'Profil complet'
        : `${this.missingInfo} informations à compléter`
    },
    score() {
      return this.loading ? this.max : this.profileScore
    },
    missingInfo() {
      return this.loading ? '' : this.max - this.score
    }
  },
  mounted() {
    this.loading = true

    this[LOAD_USER_PROFILE](this.$adal.user.profile)
      .catch(error => {
        this.showFailure(
          'Echec lors de la récuperation de votre profil: ' + error.message
        )
      })
      .finally(() => (this.loading = false))
  },
  methods: {
    ...mapActions(useStore, [LOAD_USER_PROFILE])
  }
}
</script>

<style scoped>
#home {
  padding-top: 100px;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  color: #033f3c;
}
#progress {
  margin-bottom: 30px;
}
.progress-bar {
  color: #033f3c;
  background-color: #18b0b9;
}
.btn-primary {
  background-color: #033f3c;
}
.btn-primary:hover {
  background-color: #18b0b9;
}
.btn-primary {
  border: none;
}
</style>
