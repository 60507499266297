import { mapState } from 'pinia'
import { useStore } from '@/stores'
import { PROJECTS } from '@/stores/getter-types'
import { isEmpty } from '@/stores/utils'

const ProjectMixin = {
  computed: {
    ...mapState(useStore, [PROJECTS]),
    projectsFormatted() {
      return this[PROJECTS].map(p => ({
        value: p.Id,
        text: this.formatProjectName(p)
      }))
    }
  },
  methods: {
    formatProjectName(project) {
      return project.Name.replace('-', '').trim()
    },
    formatProjectPeriod(project) {
      return !project.StartDate || !project.EndDate
        ? ''
        : project.StartDate != project.EndDate
        ? `du ${new Date(project.StartDate).toLocaleDateString(
            'fr-FR'
          )} au ${new Date(project.EndDate).toLocaleDateString('fr-FR')}`
        : `le ${new Date(project.StartDate).toLocaleDateString('fr-FR')}`
    },
    formatProjectDayCount(project) {
      return project.DayCount ? `Commandé : ${project.DayCount} J` : ''
    },
    formatProjectConsumed(project) {
      return `Imputé : ${project.Consumed || 0} J`
    },
    formatProjectAltText(project) {
      return !isEmpty(project.ClientId)
        ? [
            this.formatProjectPeriod(project),
            this.formatProjectDayCount(project),
            this.formatProjectConsumed(project)
          ]
            .filter(s => !!s)
            .join(' - ')
        : ''
    }
  }
}

export default ProjectMixin
