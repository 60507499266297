<template>
  <b-form-group :invalid-feedback="feedback" :state="state">
    <template #label>
      <b-form-row>
        <b-col cols="5" class="col-form-label pt-0">Montant</b-col>
        <b-col cols="5" class="col-form-label pt-0">
          Taux
          <span
            title="Choisir la zone de TVA applicable à ces frais."
            class="ml-1"
            v-b-tooltip.html
            ><fa-icon icon="info-circle"
          /></span>
          <CountrySelector
            v-model="ndf.VatZone"
            :disabled="ndfIsReadOnly"
            class="float-right"
          />
        </b-col>
        <b-col cols="1" class="text-right col-form-label pt-0">TTC</b-col>
        <b-col cols="1"></b-col>
      </b-form-row>
    </template>
    <b-form-row
      v-for="(vat, idx) in ndf.VatItems"
      :key="vat.Rate"
      class="align-items-center"
      :class="{ 'mb-1': idx != ndf.VatItems.length - 1 }"
    >
      <b-col cols="5">
        <b-input
          type="number"
          v-model="vat.Amount"
          min="0"
          step="0.01"
          :disabled="ndfIsReadOnly"
          required
        />
      </b-col>
      <b-col cols="5">
        <b-select
          ref="rateInputs"
          v-model="vat.Rate"
          :options="getRates(vat.Rate)"
          :disabled="lockVatRates"
          :state="!isVatRateValid(vat.Rate) ? false : undefined"
        />
      </b-col>
      <b-col cols="1" class="text-center">
        <b-checkbox v-model="vat.Included" :disabled="lockVatItems" switch />
      </b-col>
      <b-col
        v-if="!lockVatItems && items.length > 1"
        cols="1"
        class="text-center"
      >
        <small title="Supprimer" v-b-tooltip.hover.left
          ><fa-icon
            icon="times"
            class="text-danger btn-icon"
            :disabled="ndf.VatItems.length == 1"
            @click="deleteVatItem(vat)"
        /></small>
      </b-col>
    </b-form-row>
    <b-form-row v-if="isIntraCommunityPurchase">
      <b-col offset="5" cols="5" class="text-right">
        <b-badge variant="info">TVA intra-communautaire</b-badge>
      </b-col>
    </b-form-row>
    <b-form-row
      v-if="!lockVatItems"
      v-show="unusedRates.length"
      class="align-items-center"
      :class="{ 'mt-3': !!ndf.VatItems.length }"
    >
      <b-col cols="5">
        <b-input
          ref="newItemAmount"
          type="number"
          v-model="newItem.Amount"
          min="0"
          step="0.01"
          disabled
        />
      </b-col>
      <b-col cols="5">
        <b-select
          ref="newItemRate"
          v-model="newItem.Rate"
          :options="getRates(newItem.Rate, false)"
          disabled
        />
      </b-col>
      <b-col cols="1" class="text-center">
        <b-checkbox
          ref="newItemIncluded"
          v-model="newItem.Included"
          switch
          disabled
        />
      </b-col>
      <b-col cols="1" class="text-center">
        <small title="Ajouter" class="align-middle" v-b-tooltip.hover.left
          ><fa-icon
            icon="plus"
            class="text-primary btn-icon"
            @click="addVatItem"
        /></small>
      </b-col>
    </b-form-row>
  </b-form-group>
</template>
<script>
import { mapState } from 'pinia';
import { useNdfStore } from '@/stores/ndfs';
import { NdfStatus, VatRates, VatZone } from '@/assets/js/Constants.js'
import CountrySelector from '@/components/Tool/CountrySelector.vue'

const getSortedRates = category =>
  [...(category?.ApplicableRates ?? VatRates)]
    .sort((a, b) =>
      a < 0 || Object.is(a, -0) ? -1 : b < 0 || Object.is(b, -0) ? 1 : b - a
    )
    .map(r => Math.abs(r))
const DEFAULT_VAT_INCLUDED = true

export default {
  name: 'VatItemList',
  components: { CountrySelector },
  props: {
    ndf: { type: Object, required: true },
    category: { type: Object },
    validated: { type: Boolean }
  },
  data() {
    return {
      items: [],
      newItem: {
        Amount: undefined,
        Rate: undefined,
        Included: DEFAULT_VAT_INCLUDED
      },
      intraCommunityItem: { Amount: 0, Rate: 0.2, Included: true },
      nonEuropeanItem: { Amount: 0, Rate: 0, Included: true }
    }
  },
  computed: {
    ...mapState(useNdfStore, ['categories']),
    intraCommunityCategory() {
      return this.categories.find(c => c.Code === '00000')
    },
    isFrenchPurchase() {
      return this.ndf.VatZone == VatZone.France
    },
    isIntraCommunityPurchase() {
      return this.ndf.VatZone == VatZone.Europe
    },
    isNonEuropeanPurchase() {
      return this.ndf.VatZone == VatZone.Other
    },
    ndfIsReadOnly() {
      return (
        this.ndf.Status == NdfStatus.Validated ||
        this.ndf.Status == NdfStatus.Payed
      )
    },
    rates() {
      return getSortedRates(this.isIntraCommunityPurchase ? this.intraCommunityCategory : this.category)
    },
    usedRates() {
      return this.ndf.VatItems.map(v => v.Rate)
    },
    unusedRates() {
      return this.rates.filter(r => this.usedRates.indexOf(r) == -1)
    },
    lockVatRates() {
      return (
        this.isNonEuropeanPurchase ||
        this.ndfIsReadOnly
      )
    },
    lockVatItems() {
      return (
        this.isIntraCommunityPurchase ||
        this.isNonEuropeanPurchase ||
        this.ndfIsReadOnly
      )
    },
    hasVatItems() {
      return !!this.ndf.VatItems.length
    },
    areAllAmountsValid() {
      return !this.ndf.VatItems.find(vat => vat.Amount < 0)
    },
    areAllRatesValid() {
      return !this.ndf.VatItems.find(vi => !this.isVatRateValid(vi.Rate))
    },
    isValid() {
      return (
        this.hasVatItems && this.areAllAmountsValid && this.areAllRatesValid
      )
    },
    state() {
      return this.validated ? this.isValid : undefined
    },
    feedback() {
      return !this.hasVatItems
        ? 'Veuillez ajouter au moins une ligne de TVA.'
        : !this.areAllAmountsValid
        ? 'Les montants ne peuvent pas être négatifs.'
        : !this.areAllRatesValid
        ? "Au moins un des taux de TVA n'est pas valide pour cette catégorie."
        : ''
    },
    isDirty() {
      return this.items.length > 1 || this.items[0].Amount !== undefined
    }
  },
  watch: {
    category: function (_, old) {
      if (!this.isDirty && this.items[0].Rate == getSortedRates(old)[0]) {
        this.items[0].Rate = this.rates[0]
      }

      this.checkVatItems()
      this.resetNewItem()
    },
    'ndf.VatZone': function (value) {
      this.ndf.IntraCommunityPurchase = value == VatZone.Europe
      this.updateVatItems()
      this.checkVatItems()
    },
    'ndf.VatItems': {
      handler: function () {
        this.resetNewItem()
      },
      deep: true
    }
  },
  mounted() {
    this.items = this.ndf.VatItems
    this.newItem.Rate = this.unusedRates[0]

    if (this.items.length == 0) {
      this.addVatItem()
    }
  },
  methods: {
    updateVatItems() {
      this.ndf.VatItems = this.isIntraCommunityPurchase
        ? [this.intraCommunityItem]
        : this.isNonEuropeanPurchase
        ? [this.nonEuropeanItem]
        : this.items
    },
    checkVatItems() {
      this.$refs.rateInputs?.forEach(input => {
        const msg =
          this.isFrenchPurchase && !this.isVatRateValid(input.value)
            ? "Ce taux n'est pas valide pour cette catégorie."
            : ''

        input.$el.setCustomValidity(msg)
      })
    },
    getRates(rate, includeRate = true) {
      return this.isNonEuropeanPurchase
        ? [this.getVatRateItem(this.nonEuropeanItem.Rate)]
        : (includeRate
            ? [rate, ...this.unusedRates]
            : [...this.unusedRates]
          ).map(this.getVatRateItem)
    },
    isVatRateValid(rate) {
      return (
        this.isNonEuropeanPurchase ||
        this.rates.indexOf(rate) > -1
      )
    },
    getVatRateItem(r) {
      return { value: r, text: this.formatVatRate(r) }
    },
    formatVatRate(rate) {
      return `${rate * 100} %`.replace('.', ',')
    },
    addVatItem() {
      this.ndf.VatItems.push(this.newItem)
      this.resetNewItem()
    },
    deleteVatItem(vat) {
      const idx = this.ndf.VatItems.findIndex(v => v.Rate == vat.Rate)

      if (idx > -1) {
        this.ndf.VatItems.splice(idx, 1)
      }

      this.resetNewItem()
    },
    resetNewItem() {
      this.newItem = this.unusedRates.length
        ? {
            Amount: undefined,
            Rate: this.unusedRates[0],
            Included: DEFAULT_VAT_INCLUDED
          }
        : { Amount: undefined, Rate: undefined, Included: DEFAULT_VAT_INCLUDED }
    },
    setNewItemCustomValidity(msg) {
      if (!this.lockVatItems) {
        this.$refs.newItemAmount.$el.setCustomValidity(msg)
        this.$refs.newItemRate.$el.setCustomValidity(msg)
        this.$refs.newItemIncluded.$el.children[0].setCustomValidity(msg)
      }
    },
    validate() {
      this.setNewItemCustomValidity(!this.hasVatItems ? this.feedback : '')

      return this.isValid
    }
  }
}
</script>
