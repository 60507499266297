<template>
  <div>
    <b-toast
      v-model="successToastVisible"
      title="Validation"
      variant="success"
      toaster="b-toaster-top-full"
      solid
    >
      Profil enregistré avec succès.
    </b-toast>
    <b-toast
      v-model="failureToastVisible"
      title="Erreur"
      variant="danger"
      toaster="b-toaster-top-full"
      solid
    >
      {{ alerts.failure.reason }}
    </b-toast>
    <b-row>
      <b-col cols="12" md="7" lg="8" xl="6">
        <PersonalInfoValidationCard
          :userProfile="userProfile"
          @failed="onFailed"
          class="mb-4"
        />
      </b-col>
      <b-col cols="12" md="5" lg="4" xl="6">
        <b-row>
          <b-col cols="5" md="12" xl="6">
            <PhotoValidationCard
              :userProfile="userProfile"
              @failed="onFailed"
              class="mb-4"
            />
          </b-col>
          <b-col cols="7" md="12" xl="6">
            <AdministrativeInfoCard
              :userProfile="userProfile"
              class="mb-4"
              @user-saved="showSuccess"
              @user-not-saved="showFailure"
            />
          </b-col>
        </b-row>
        <!-- <DocumentsValidationCard
          :userProfile="userProfile"
          @failed="onFailed"
          class="mb-4"
        /> -->
        <b-row :hidden="!isES(userProfile)" no-gutters>
          <b-col class="text-center">
            <b-button
              :pressed="isHidden"
              variant="warning"
              class="mx-auto w-75 mb-4"
              @click="isHidden = !isHidden"
              ><fa-icon :icon="['far', isHidden ? 'eye-slash' : 'eye']" />
              {{ isHidden ? 'Invisible' : 'Visible' }} sur
              Insiders.coop</b-button
            >
          </b-col>
        </b-row>
        <b-row>
          <b-col class="text-center">
            <b-button
              variant="danger"
              class="mx-auto w-50"
              @click="DeleteUser()"
              ><fa-icon icon="trash-alt" /> Supprimer</b-button
            >
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapActions } from 'pinia'
import { useStore } from '@/stores'
import { DELETE_USER } from '@/stores/action-types'
import { UserService } from '@/services'
import { DataURLMixin, UserMixin } from '@/mixins'
import PersonalInfoValidationCard from './PersonalInfoValidationCard'
import PhotoValidationCard from './PhotoValidationCard'
import AdministrativeInfoCard from './AdministrativeInfoCard'
// import DocumentsValidationCard from './DocumentsValidationCard'

export default {
  name: 'UserValidation',
  components: {
    PersonalInfoValidationCard,
    PhotoValidationCard,
    AdministrativeInfoCard
    // DocumentsValidationCard
  },
  mixins: [DataURLMixin, UserMixin],
  props: {
    userProfile: { type: Object, required: true }
  },
  data() {
    return {
      alerts: {
        success: {
          countdown: 0
        },
        failure: {
          countdown: 0,
          reason: String
        }
      }
    }
  },
  computed: {
    successToastVisible: {
      get() {
        return !!this.alerts.success.countdown
      },
      set() {
        this.alerts.success.countdown = 0
      }
    },
    failureToastVisible: {
      get() {
        return !!this.alerts.failure.countdown
      },
      set() {
        this.alerts.failure.countdown = 0
      }
    },
    isHidden: {
      get() {
        return this.userProfile.IsHiddenOnWebsite
      },
      set(hidden) {
        if (hidden) {
          this.HideUser(this.userProfile)
        } else {
          this.UnhideUser(this.userProfile)
        }
      }
    }
  },
  methods: {
    ...mapActions(useStore, [DELETE_USER]),
    showSuccess() {
      this.alerts.success.countdown = 1
    },
    showFailure(evtData) {
      this.alerts.failure.reason = evtData
      this.alerts.failure.countdown = 2
    },
    onFailed(err) {
      this.showFailure(`[${err.name}] ${err.message}`)
    },
    HideUser(user) {
      UserService.SetUserWebsiteVisibility(user.Upn, false)
        .then(() => {
          user.IsHiddenOnWebsite = true
        })
        .catch(this.handleError)
    },
    UnhideUser(user) {
      UserService.SetUserWebsiteVisibility(user.Upn, true)
        .then(() => {
          user.IsHiddenOnWebsite = false
        })
        .catch(this.handleError)
    },
    DeleteUser() {
      this[DELETE_USER](this.userProfile.Upn)
        .then(() => {
          this.$emit('user-deleted', this.userProfile.Upn)
        })
        .catch(this.handleError)
    },
    handleError(err) {
      this.$emit(
        'failed',
        err.response
          ? new Error(err.response.data.Message, { cause: err })
          : err
      )
    }
  }
}
</script>

<style scoped>
img.photo {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  object-fit: cover;
}
.fa-check-circle.validated {
  color: green;
}

.fa-times-circle.rejected {
  color: red;
}
</style>
