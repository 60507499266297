import { ref } from 'vue'
import { useDates } from '@/composables/useDates'
import { GetMyRemoteActivity, GetAllRemoteActivity } from '@/services'

const remoteActivity = ref([])

const loadMyRemoteActivity = () => {
  GetMyRemoteActivity()
    .then(response => (remoteActivity.value = response.data))
    .catch(err => console.error('There was an error: ', err))
}

const loadAllRemoteActivity = () => {
  GetAllRemoteActivity()
    .then(response => (remoteActivity.value = response.data))
    .catch(err => console.error('There was an error: ', err))
}

const isRemoteDay = ({ Date }) => {
  const { isSameDay } = useDates()

  return remoteActivity.value?.some(isSameDay(Date))
}

export const useRemoteActivity = () => {
  return {
    remoteActivity,
    loadAllRemoteActivity,
    loadMyRemoteActivity,
    isRemoteDay
  }
}
