<template>
  <UserInfoBox
    box-label="Profil pro"
    :loading="loading"
    :hideSaveButton="!isDirty"
    profilePro
    @user-saved="onUserSaved($event)"
    @user-not-saved="onUserNotSaved($event)"
  >
    <template #header="{ label }">
      {{ label }}
    </template>
    <b-form-group
      label="Disponibilité"
      label-for="availability"
      label-cols-sm="4"
      label-cols-lg="3"
      class="text-right"
    >
      <b-checkbox id="availability" v-model="isAvailable" class="my-2" switch />
    </b-form-group>
    <b-form-group label="Mini bio" label-for="short-bio" class="block">
      <b-textarea id="short-bio" v-model="shortBio" rows="5" class="my-2" />
    </b-form-group>
    <b-form-group
      label="Compétences"
      label-for="skill-list-input"
      class="block"
    >
      <b-form-tags
        v-model="skills"
        placeholder="Ajoutez une compétence"
        class="mb-2"
        no-outer-focus
      >
        <template
          #default="{
            tags,
            placeholder,
            inputAttrs,
            inputHandlers,
            tagVariant,
            removeTag
          }"
        >
          <b-input-group>
            <b-input-group-prepend is-text>
              <fa-icon icon="brain" />
            </b-input-group-prepend>
            <b-form-input
              id="skill-list-input"
              v-bind="inputAttrs"
              v-on="inputHandlers"
              :placeholder="placeholder"
            />
          </b-input-group>
          <b-form-text class="mb-2">
            Appuyez sur ENTREE pour valider.
          </b-form-text>
          <div
            class="d-inline-block w-100 text-left"
            style="font-size: 1.25rem"
          >
            <b-form-tag
              v-for="tag in tags"
              :key="tag"
              :title="tag"
              :variant="tagVariant"
              class="mr-2"
              @remove="onDeleteSkill({ skill: tag, removeTag })"
            />
          </div>
        </template>
      </b-form-tags>
      <b-form-group
        id="adrGroup"
        label="Tarif journalier"
        label-for="dailyRate"
        label-cols-sm="4"
        label-cols-lg="3"
      >
        <b-form-input id="dailyRate" type="text" v-model="dailyRate" required />
      </b-form-group>
      <b-form-group
        id="locationGroup"
        label="Lieu de travail préféré"
        label-for="prefLocation"
        label-cols-sm="4"
        label-cols-lg="3"
      >
        <b-form-input
          id="prefLocation"
          type="text"
          v-model="prefLocation"
          required
        />
      </b-form-group>
    </b-form-group>
  </UserInfoBox>
</template>
<script>
import { mapState, mapActions } from 'pinia'
import { useStore } from '@/stores'
import { USER_PROFILE, ALL_SKILLS } from '@/stores/state-keys'
import {
  SET_USER_IS_AVAILABLE,
  SET_USER_SHORT_BIO,
  SET_USER_SKILLS,
  SET_USER_ADR,
  SET_USER_PREFERRED_LOCATION,
  POP_USER_SKILL
} from '@/stores/mutation-types'
import { PUT_MY_SKILL, DELETE_MY_SKILL } from '@/stores/action-types'
import UserInfoBox from '@/components/User/UserInfoBox.vue'

export default {
  name: 'SkillBox',
  components: { UserInfoBox },
  props: {
    loading: { type: Boolean, default: false }
  },
  data() {
    return {
      isDirty: false
    }
  },
  computed: {
    ...mapState(useStore, [USER_PROFILE, ALL_SKILLS]),
    isAvailable: {
      get() {
        return this[USER_PROFILE].IsAvailable
      },
      set(value) {
        this.setDirty(true)
        this[SET_USER_IS_AVAILABLE](value)
      }
    },
    shortBio: {
      get() {
        return this[USER_PROFILE].ShortBio
      },
      set(value) {
        this.setDirty(true)
        this[SET_USER_SHORT_BIO](value)
      }
    },
    skills: {
      get() {
        return this[USER_PROFILE].Skills
      },
      set(value) {
        this[SET_USER_SKILLS](value)
      }
    },
    dailyRate: {
      get() {
        return this[USER_PROFILE].Adr
      },
      set(value) {
        this.setDirty(true)
        this[SET_USER_ADR](value)
      }
    },
    prefLocation: {
      get() {
        return this[USER_PROFILE].PreferredLocation
      },
      set(value) {
        this.setDirty(true)
        this[SET_USER_PREFERRED_LOCATION](value)
      }
    }
  },
  watch: {
    async skills(newValue, oldValue) {
      if (newValue.length == oldValue.length + 1) {
        await Promise.all(
          newValue
            .filter(s => oldValue.indexOf(s) == -1)
            .map(skill =>
              this[PUT_MY_SKILL](skill).catch(error => {
                this[POP_USER_SKILL](skill)
                this.$emit('add-skill-failed', { skill, error })
              })
            )
        )
      }
    }
  },
  methods: {
    ...mapActions(useStore, [
      SET_USER_IS_AVAILABLE,
      SET_USER_SHORT_BIO,
      SET_USER_ADR,
      SET_USER_PREFERRED_LOCATION,
      SET_USER_SKILLS,
      POP_USER_SKILL,
      PUT_MY_SKILL,
      DELETE_MY_SKILL
    ]),
    setDirty(dirty) {
      this.isDirty = dirty && !this.loading
    },
    onDeleteSkill({ skill, removeTag }) {
      this[DELETE_MY_SKILL](skill)
        .then(() => removeTag(skill))
        .catch(error => this.$emit('remove-skill-failed', { skill, error }))
    },
    onUserSaved(event) {
      this.setDirty(false)
      this.$emit('user-saved', event)
    },
    onUserNotSaved(event) {
      this.$emit('user-not-saved', event)
    }
  }
}
</script>
<style scope>
#skills label {
  text-align: left;
}
</style>
