import axios from 'axios'
import getToken from './adal.js'
import TechnicalSvc from './Technical'
import { ProjectRoutes } from './routes'

export default {
  async CreateProject(project) {
    const token = await getToken()
    return axios.post(ProjectRoutes.PROJECTS, project, {
      headers: { Authorization: token }
    })
  },
  async GetProjects() {
    const token = await getToken()
    return axios.get(ProjectRoutes.PROJECTS, {
      headers: {
        Authorization: token,
        ShowAsAdmin: TechnicalSvc.GetShowAsAdmin()
      }
    })
  },
  async GetProjectsWithoutCP() {
    const token = await getToken()
    return axios.get(ProjectRoutes.PROJECT_WITHOUT_CP, {
      headers: {
        Authorization: token,
        ShowAsAdmin: TechnicalSvc.GetShowAsAdmin()
      }
    })
  },
  async SetProject(project) {
    const token = await getToken()
    return axios.put(ProjectRoutes.PROJECT(project.Id), project, {
      headers: { Authorization: token }
    })
  },
  async DeleteProject(projectId) {
    const token = await getToken()
    return axios.delete(ProjectRoutes.PROJECT(projectId), {
      headers: { Authorization: token }
    })
  }
}
