<template>
  <div id="app">
    <NavBar />
    <router-view />
    <!-- <code>{{ env }}</code> -->
  </div>
</template>

<script>
import { mapActions } from 'pinia'
import { useStore, Actions } from '@/stores'
import NavBar from '@/components/NavBar.vue'

export default {
  name: 'EsPortal',
  components: {
    NavBar
  },
  data() {
    return {
      // env: process.env
    }
  },
  mounted() {
    if (!this.$adal.checkRoles(['ES_SALARIED', 'Admin'])) {
      //this.$router.push({ name: 'enrolment' })
    }

    this[Actions.LOAD_NOTIFICATIONS]()
    setInterval(() => this[Actions.LOAD_NOTIFICATIONS](), 60 * 1000) // Every minute
  },
  methods: {
    ...mapActions(useStore, [Actions.LOAD_NOTIFICATIONS])
  }
}
</script>

<style>
body {
  background-color: #ffffff;
}
#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* text-align: center; */
  color: #2c3e50;
}
</style>
